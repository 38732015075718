import {LOCALES} from './locales'

export const messages = {
  [LOCALES.RUSSIAN]: {
    action: '<p>Подпишитесь на наш <a href="https://t.me/site3d_configurator" target="_blank">телеграм-канал</a>, напишите об этом <a href="https://t.me/site3d_support" target="_blank">администратору</a> и получите <span class="hot">дополнительные 10&nbsp;дней на&nbsp;максимальном тарифе</span>.</p>',
    additional_tools: 'Дополнительные инструменты',
    additional_tools_move: 'Переместить пользователю',
    additional_tools_move_error: 'Не удалось переместить проект пользователю',
    additional_tools_move_success: 'Проект был успешно перемещен',
    admin_menu_users: 'Пользователи',
    admin_menu_catalog_models: 'Каталог моделей',
    admin_menu_news: 'Новости',
    admin_menu_posts: 'Блог',
    admin_menu_faq: 'FAQ',
    admin_menu_system: 'Система',
    admin_users_empty: 'Пользователей нет',
    admin_users_remove_confirm: 'Действительно хотите удалить этого пользователя?',
    admin_users_configurators: '{configurators}',
    admin_catalog_models_empty: 'Модели каталога не найдены',
    admin_catalog_models_remove_confirm: 'Действительно хотите удалить эту модель?',
    admin_news_remove_confirm: 'Действительно хотите удалить эту новость?',
    admin_posts_remove_confirm: 'Действительно хотите удалить этот пост?',
    admin_faq_remove_confirm: 'Действительно хотите удалить этот вопрос?',
    admin_system_clear_data: 'Очистить неиспользуемые пользовательские данные',
    admin_system_update_configurator_hash: 'Обновить хэши для ссылок на проекты',
    admin_system_update_domains: 'Обновить домены',
    admin_system_update_settings: 'Обновить настройки',
    admin_system_technical_works: 'Технические работы',
    align_center: 'По центру',
    align_top: 'Сверху',
    align_right_top: 'Справа вверху',
    align_right: 'Справа',
    align_right_bottom: 'Справа внизу',
    align_bottom: 'Снизу',
    align_left_bottom: 'Слева снизу',
    align_left: 'Слева',
    align_left_top: 'Слева вверху',
    anim_numbers_none: 'Нет',
    anim_numbers_all: 'Все',
    ar_models: 'Модели для AR',
    ar_models_glb: 'Для Android в формате GLB',
    ar_models_glb_hint: 'Файлы glb создаются автоматически при активации режима дополненной реальности, но вы можете загрузить свой вариант данного формата.',
    ar_models_usdz: 'Для iOS в формате USDZ',
    ar_models_usdz_hint: 'Файлы usdz создаются автоматически при активации режима дополненной реальности, но вы можете загрузить свой вариант данного формата.',
    ar_url_hint: 'Ссылка на стороннее средство просмотра AR',
    ar_markers: 'Маркеры',
    ar_markers_empty: 'Маркеры еще не добавлены',
    ar_marker_remove_confirm: 'Действительно хотите удалить этот маркер?',
    auth_caption: 'Авторизация',
    auth_days: 'Активных дней подписки: {days}',
    auth_days_no: 'Необходимо оплатить подписку',
    auth_login: 'Войти',
    auth_logout: 'Выйти',
    auth_reg: 'Регистрация',
    auth_forgot_password: 'Забыли пароль?',
    auth_forgot_password_confirm: 'Действительно хотите сбросить прежний пароль?',
    auth_forgot_password_subject: 'Восстановление пароля',
    auth_forgot_password_body: 'Ваш новый пароль для доступа к Site3D Configurator: [password]',
    backend_auth_failed: 'Неверный email или пароль',
    backend_reg_success: 'Регистрация прошла успешно',
    backend_user_exist: 'Пользователь с такой почтой уже зарегистрирован',
    backend_user_not_exist: 'Пользователь с такой почтой не зарегистрирован',
    backend_forgot_password_success: 'На вашу почту было отправлено письмо с новым паролем',
    backend_profile_edit_success: 'Профиль успешно отредактирован',
    backend_configurator_not_found: 'Запрашиваемый проект не найден',
    banner_cookieAgree: 'На этом веб-сайте используются cookie.',
    block_present_caption_main: 'Встройте 3D-модели на\u00a0сайт и\u00a0повысьте конверсию на\u00a07%',
    block_present_caption_houses: 'Встройте 3D-модели домов на\u00a0сайт и\u00a0увеличьте заявки на\u00a039%',
    block_present_caption_pavilions: 'Встройте 3D-модели беседок на\u00a0сайт и\u00a0увеличьте заявки на\u00a039%',
    block_present_caption_stairs: 'Встройте 3D-модели лестниц на\u00a0сайт и\u00a0увеличьте заявки на\u00a039%',
    block_present_caption_playgrounds: 'Встройте 3D-модели детских площадок на\u00a0сайт и\u00a0повысьте конверсию на\u00a07%',
    block_present_caption_fireplaces: 'Встройте 3D-модели каминов на\u00a0сайт и\u00a0повысьте конверсию на\u00a07%',
    block_present_caption_industry: 'Встройте 3D-модели промышленного оборудования на\u00a0сайт и\u00a0повысьте конверсию на\u00a07%',
    block_present_slogan: '<span class="textEffect"><span class="textEffect__item textEffect__item_1">Доступно&nbsp;</span><span class="textEffect__item textEffect__item_2"><span class="textEffect__itemContent textEffect__itemContent_2">любому&nbsp;бизнесу от&nbsp;550&nbsp;₽</span></span></span>',
    block_present_description: '<ul>' +
      '<li>Все в&nbsp;браузере и&nbsp;без&nbsp;программирования</li>' +
      '<li>Бесплатно 10 дней и&nbsp;демо-проект</li>' +
      '<li><span class="hot">Нет ограничений на число 3D-моделей</span></li>' +
      '<li>В&nbsp;поддержке только живые люди</li>' +
      '<li>API для&nbsp;более тесной интеграции</li>' +
      '<li><span class="russia">Работаем из&nbsp;России</span></li>' +
      '</ul>',
    block_present_gift: 'Заберите подарок',
    block_present_gift_text: '<h2>Дополнительно 10&nbsp;дней на&nbsp;максимальном тарифе</h2>' +
      '<ul>' +
      '<li><a href="/reg" target="_blank">Зарегистрируйтесь</a>, если еще не делали этого</li>' +
      '<li>Подпишитесь на наш <a href="https://t.me/site3d_configurator" target="_blank">телеграм-канал</a></li>' +
      '<li>Пришлите нам <a href="https://t.me/site3d_support" target="_blank">в поддержку</a> слово <span class="hot">ПОДАРОК</span> и e-mail, указанный при регистрации</li>' +
      '</ul>',
    block_present_try: 'Увеличьте продажи',
    block_present_widget_description_main: 'Пример 3D-конфигуратора на сайте',
    block_present_widget_description_houses: 'Пример 3D-конфигуратора дома',
    block_present_widget_description_pavilions: 'Пример 3D-конфигуратора беседки',
    block_present_widget_description_stairs: 'Пример 3D-конфигуратора лестницы',
    block_present_widget_description_playgrounds: 'Пример 3D-конфигуратора детской площадки на сайте',
    block_present_widget_description_fireplaces: 'Пример 3D-конфигуратора камина',
    block_present_widget_description_industry: 'Пример 3D-конфигуратора КАЗС',
    block_present_widget_link_main_1: 'Пример ссылки на просмотр 3D',
    block_present_widget_link_houses_1: 'Пример ссылки на просмотр 3D-модели загородного дома',
    block_present_widget_link_pavilions_1: 'Пример ссылки на просмотр 3D-конструктора зимнего сада',
    block_present_widget_link_stairs_1: 'Пример 3D-конструктора лестницы со\u00a0сменой ограждений',
    block_present_widget_link_stairs_2: 'Пример ссылки на просмотр 3D-модели лестницы с\u00a0интерактивной анимацией',
    block_present_widget_link_playgrounds_1: 'Пример ссылки на просмотр 3D-модели детской площадки',
    block_present_widget_link_fireplaces_1: 'Пример ссылки на просмотр 3D-модели камина',
    block_present_widget_link_industry_1: 'Пример ссылки на просмотр 3D-модели азотного узла',
    block_problem_caption: 'Решаем проблему показа товара на сайте',
    block_problem_description: '2D-картинка может приводить к потери клиентов, когда не может точно передать нюансы форм и свойств товара',
    block_problem_product: '2D-изображение мебельной ручки',
    block_problem_client: 'Шкаф покупателя',
    block_problem_solution: 'Интерактивное 3D нагляднее',
    block_show_name: 'Интерактивный 3D просмотр',
    block_show_description: 'Облачный сервис по внедрению интерактивных 3D-моделей в виде блоков на сайте или ссылок на просмотр с возможностью конфигурирования.',
    block_gift_caption: 'Узнайте, как 3D-технологии влияют на продажи',
    block_gift_description: 'Посмотрите, как другие применяют современные инструменты маркетинга',
    block_steps_caption: 'Как это работает?',
    block_steps_description: 'На всех этапах дадим четкие инструкции. Мы на связи ежедневно.',
    block_steps_1_caption: 'Загружаете свою 3D-модель в\u00a0наш сервис',
    block_steps_1_description: '<p>Где можно взять 3D-модели, если их нет у ваших сотрудников:</p>' +
      '<ul>' +
      '<li>Запросить по нашей <a href="/docs/guide_ru.pdf" target="_blank">инструкции</a> у производителей или у тех, кто предоставил вам статичные рендеры</li>' +
      '<li>Заказать у наших партнеров, <a href="/support">написав в поддержку</a></li>' +
      '</ul>' +
      '<p>Еще мы написали <a href="/docs/guide_ru.pdf" target="_blank">руководство</a> по подготовке моделей и работе внутри сервиса.</p>',
    block_steps_2_caption: 'Настраиваете просмотр',
    block_steps_2_description: 'Справится любой сотрудник, а если возникнут вопросы, наша <a href="/support">служба поддержки</a> всегда на связи.',
    block_steps_3_caption: 'Получаете готовый код на\u00a0сайт или ссылку на просмотр',
    block_steps_3_description: 'Наш код можно встроить в любой сайт за 5 минут.',
    block_steps_4_caption: 'Вставляете код на\u00a0сайт или делитесь ссылкой',
    block_steps_4_description: 'А еще в личном кабинете Вы сможете сделать <span class="hot">фото и видео материалы</span> из 3D-моделей.',
    block_steps_video_name: 'Как работает сервис Site3D Configurator',
    block_steps_video_description: 'Поэтапно рассказываем про внедрение блоков на сайт с 3D просмотром.',
    block_steps_try: 'Протестируйте бесплатно',
    block_ideas_caption: 'Привлеките больше покупателей',
    block_ideas_description: 'Вдохновитесь нашими идеями применения 3D-моделей или придумайте свои',
    block_ideas_image_description: 'Создание 3D-конфигуратора в сервисе Site3D Configurator с показом результата в дополненной реальности',
    block_ideas_structure_caption: 'Взгляд изнутри',
    block_ideas_structure_description: 'Взрыв-схема поможет вам быстро и наглядно показать, из чего состоит продукт или услуга',
    block_ideas_action_caption: 'Товар в действии',
    block_ideas_action_description: 'Анимация вместо статичного фото расскажет о товаре больше и привлечет к себе внимание покупателя',
    block_ideas_manual_caption: 'Интерактивная анимация',
    block_ideas_manual_description: 'Задержите внимание пользователя эффектной анимацией 3D-моделей, управляемой колесиком мыши или свайпом по экрану',
    block_ideas_configurator_caption: 'Свой вариант продукта',
    block_ideas_configurator_description: 'Дайте покупателям возможность настраивать товар под свои потребности и запросы',
    block_ideas_tour_caption: '3D тур через модели',
    block_ideas_tour_description: 'Наши 3D-туры сделаны не из фотографий, а из 3D-моделей, что позволяет устанавливать любые точки обзора и легко вносить любые изменения в сцене',
    block_ideas_text_caption: 'Свои надписи на модели',
    block_ideas_text_description: 'Ваши клиенты могут наносить на различные части 3D-модели произвольный текст и отправлять результат вам по ссылке',
    block_ideas_move_caption: 'Перемещение моделей',
    block_ideas_move_description: 'Предоставьте покупателям расставить несколько моделей в одном пространстве',
    block_benefits_caption: 'Добейтесь результата',
    block_benefits_description: 'Повысьте эффективность вашего бизнеса',
    block_benefits_1_caption: 'Выделитесь\nсреди конкурентов',
    block_benefits_1_description: '3D-технологии станут вашим сильным конкурентным преимуществом',
    block_benefits_2_caption: 'Поднимитесь\nв поисковиках',
    block_benefits_2_description: '3D-модели увеличивают время пребывания на сайте, что положительно влияет на поисковое продвижение',
    block_benefits_3_caption: 'Упростите\nработу с\u00a0клиентом',
    block_benefits_3_description: 'Дайте клиенту возможность самому настроить товар и оценить варианты вместо общения с менеджерами',
    block_benefits_4_caption: 'Увеличьте\nпродажи на\u00a0сайте',
    block_benefits_4_description: 'По данным исследования компании «Связной» 3D-модели дают рост конверсии в среднем на 7%',
    block_capabilities_caption: 'С нами все получится',
    block_capabilities_description: 'Получите эффективное решение своей задачи',
    block_capabilities_optimize_caption: 'Эффективные алгоритмы ускорения просмотра 3D-моделей',
    block_capabilities_optimize_name: 'Ускорения просмотра 3D-моделей с большим количеством элементов',
    block_capabilities_optimize_description: 'Уникальные алгоритмы увеличение скорости отклика при просмотре тяжелых 3D-моделей',
    block_capabilities_formats_caption: 'Поддержка популярных форматов 3D-моделей',
    block_capabilities_formats_description: 'Наш сервис позволяет загружать 3D-модели таких форматов, как GLB, FBX, STL, OBJ, 3DS и другие',
    block_capabilities_textures_caption: 'Безграничные варианты\nцветов и\u00a0текстур',
    block_capabilities_textures_description: 'Возможность наглядно продемонстрировать различные расцветки товара (при этом пользователь может примерить на модель собственное изображение)',
    block_capabilities_environment_caption: 'Товары вписаны\nв\u00a0окружение',
    block_capabilities_environment_description: 'Покажи свой продукт с реалистичной панорамой, где 3D-модели располагаются на поверхности, а не «висят в воздухе»',
    block_capabilities_ar_caption: '3D примерка\nв\u00a0реальности',
    block_capabilities_ar_description: 'Дополненная реальность позволяет разместить товар в настоящем мире (например, примерить мебель в своем интерьере)',
    block_capabilities_animation_caption: 'Анимация\nс\u00a0поясняющим текстом',
    block_capabilities_animation_description: 'Помогает быстро донести ключевую информацию о продукте',
    block_capabilities_video_caption: 'Видео-обзоры\nнужных вариантов',
    block_capabilities_video_description: 'Можете в один клик создавать видео-ролики о своих товарах с различными цветами и комплектацией',
    block_capabilities_labels_caption: 'Удобный просмотр\nотдельных элементов',
    block_capabilities_labels_description: 'Интерактивные метки помогают сфокусироваться на преимуществах и особенностях вашего продукта',
    block_capabilities_parts_caption: 'Замена и скрытие\nдеталей продукта',
    block_capabilities_parts_description: 'Возможность увидеть различные варианты вашего предложения',
    block_capabilities_lod_caption: 'Разные уровни\nдетализации',
    block_capabilities_lod_description: 'Во время приближения камеры к\u00a0объекту можно подгружать больше деталей продукта',
    block_capabilities_stat_caption: 'Статистика взаимодействия с\u00a0товаром',
    block_capabilities_stat_description: 'Теперь вы сможете понимать, в\u00a0каких товарах в\u00a0большей степени заинтересованы ваши клиенты.',
    block_capabilities_order_caption: 'Расчет стоимости\nи оформление заказа',
    block_capabilities_order_description: 'Клиенты могут видеть цену товара в зависимости от выбранных опций и оформить заявку прямо из 3D-виджета',
    block_capabilities_themes_caption: 'Индивидуальные\nтемы оформления',
    block_capabilities_themes_description: 'Поддержка уникального внешнего вида 3D-блока под ваш бренд',
    block_capabilities_promo_name: 'Промо-ролик о сервисе внедрения 3D-моделей на сайты Site3D Configurator',
    block_capabilities_promo_description: 'Site3D Configurator – онлайн сервис для быстрого внедрения 3D-конфигураторов на сайты с поддержкой таких полезных для бизнеса возможностей, как: загрузка 3D-моделей популярных форматов, управление анимацией, замена текстур и комплектующих.',
    block_capabilities_ar_video_name: 'Дополненная реальность в сервисе внедрения 3D-моделей на сайты Site3D Configurator',
    block_capabilities_ar_video_description: '3D-виджет сервиса Site3D Configurator позволяет примерять модели к реальным интерьерам, что позволяет, например, сделать более осмысленный выбор какой-то вещи перед её покупкой.',
    block_promo_name: 'Российская платформа создания 3D презентаций товаров и услуг',
    block_promo_description: 'Демонстрация создания 3D-виджета для вставки на сайт с целью увеличения конверсии онлайн-продаж.',
    block_api_caption: 'Документированный API для продвинутой интеграции',
    block_api_description: 'Когда нужна связь скриптов Вашего сайта с нашим виджетом',
    block_api_content: '<p><a href="/docs/api_ru.pdf" target="_blank">Документация по программному интерфейсу Site3D Configurator</a></p>' +
      '<p>Протестируйте работу API на примере данного 3D-виджета</p>',
    block_api_test_color: 'Сменить цвет',
    block_api_test_own_texture: 'Загрузить свою текстуру',
    block_api_test_share: 'Поделиться выбором пользователя',
    block_api_test_pdf_button: 'Создать PDF документ',
    block_api_test_pdf_start: 'Создание PDF документа',
    block_api_test_screenshot_button: 'Сделать скриншот',
    block_api_test_screenshot_start: 'Создание скриншота',
    block_api_test_ar: 'Дополненная реальность',
    block_api_test_ar_button: 'Примерить в AR',
    block_api_test_view: 'Управление отображением сцены',
    block_api_test_view_load: 'Загрузить сцену',
    block_api_test_view_render: 'Включить рисование сцены',
    block_api_test_no_widget: 'Не загружен 3D-виджет',
    block_api_test_no_image: 'Не загружено изображение',
    block_calmness_caption: 'С нами будет спокойно',
    block_calmness_description: 'Нацелены на долгосрочное сотрудничество',
    block_calmness_support_caption: 'Мы постоянно на\u00a0связи со своими клиентами',
    block_calmness_support_description: 'Наша служба поддержка работает ежедневно с 10:00 до 21:00. Поможем по любым вопросам.',
    block_calmness_free_caption: 'Ваши проекты доступны даже после окончания подписки',
    block_calmness_free_description: 'В этом случае вы будете переведены на&nbsp;<a href="/buy">бесплатный&nbsp;тариф</a>',
    block_calmness_availability_caption: 'Наши 3D-виджеты адаптированы под любые устройства',
    block_calmness_availability_description: 'Наше решение доступно для десктопных и мобильных устройств',
    block_calmness_safe_caption: 'Ваши 3D-модели защищены от\u00a0скачивания',
    block_calmness_safe_description: 'Пользователи могут только просматривать модели. Также можно разместить поверх модели любой водяной знак.',
    block_cases_caption: 'Наши кейсы',
    block_cases_description: 'Приносим пользу бизнесу. Расширяем границы возможностей',
    block_cases_task: 'Какая задача стояла?',
    block_cases_before: 'Было',
    block_cases_after: 'Стало',
    block_cases_result: 'Результат',
    block_cases_link: 'Посмотрите пример',
    block_cases_link_1: 'Посмотрите пример\u00a01',
    block_cases_link_2: 'Посмотрите пример\u00a02',
    block_cases_lesenka: 'Интерактивные 3D-модели лестниц с\u00a0примеркой в\u00a0реальности',
    block_cases_lesenka_task: 'Поднять число заявок на изготовление лестниц с сайта на 10-15% за счет размещения 3D-виджетов для наиболее популярных товарных позиций',
    block_cases_lesenka_before: 'Фотографии лестниц',
    block_cases_lesenka_after: '3D-обзор продукции со всевозможных ракурсов и\u00a0с\u00a0дополненной реальностью',
    block_cases_lesenka_result: '<ul>' +
      '<li>На 42% возросло среднее время пребывания пользователей на сайте</li>\n' +
      '<li>На 22% увеличилась видимость интернет-магазина в поисковых системах</li>\n' +
      '<li>На 39% увеличилось число заявок с сайта по сравнению с маем прошлого года</li>\n' +
      '</ul>',
    block_cases_playgrounds: '3D-просмотр моделей детских площадок',
    block_cases_playgrounds_task: 'Дать возможность посетителям сайта рассмотреть продукцию с любого ракурса и примерить на своем участке с помощью дополненной реальности',
    block_cases_playgrounds_before: 'Статичные рендеры и фотографии готовых объектов',
    block_cases_playgrounds_after: 'Интерактивный 3D-виджет в карточках товаров',
    block_cases_playgrounds_result: 'На 9% увеличилось число заявок на строительство детских игровых и спортивных комплексов с сайта',
    block_cases_architent: '3D-конфигуратор шатров',
    block_cases_architent_task: 'Дать возможность посетителям сайта сконструировать собственный вариант тента',
    block_cases_architent_before: 'Статичный рендер',
    block_cases_architent_after: 'Интерактивный 3D-конфигуратор',
    block_cases_architent_result: 'На 8% увеличилось число заявок с сайта',
    block_cases_aluminante: '3D-конфигуратор беседок',
    block_cases_aluminante_task: 'Показать возможные комплектации и расцветки беседок, а также анимацию остеклений',
    block_cases_aluminante_before: 'Фотографии отдельных решений',
    block_cases_aluminante_after: '3D-просмотр с возможностью просмотра анимации, смены материалов и комплектации',
    block_cases_aluminante_result: 'В 2 раза увеличилась вовлеченность посетителей в покупку.',
    block_cases_cabin: '3D-конфигуратор бытовки с анимацией',
    block_cases_cabin_task: 'Увеличить заказы на\u00a0аренду бытовок',
    block_cases_cabin_before: 'Страница услуги содержала только текст и\u00a0статичные картинки',
    block_cases_cabin_after: 'Внедрили в\u00a0описание услуги интерактивный 3D-виджет бытовки с\u00a0анимацией составных частей изделия',
    block_cases_cabin_result: 'Число клиентов на\u00a0услугу увеличилось в\u00a01.8 раз.',
    block_cases_cottage: '3D-просмотр дома',
    block_cases_cottage_task: 'Максимально эстетично показать 3D-модели загородного дома',
    block_cases_cottage_before: 'Стандартные средства просмотра: статичные рендеры и видео-обзоры',
    block_cases_cottage_after: 'Добавился эффектный 3D-виджет дома с возможностью вращения, масштабирования, просмотра планировки изнутри и конфигурирования',
    block_cases_cottage_result: 'Покупатель может рассмотреть варианты коттеджей с любого ракурса и при необходимом приближении.',
    block_cases_house: '3D-конфигуратор жилищного комплекса с планировками',
    block_cases_house_task: 'Увеличить вовлеченность пользователей в\u00a0покупку квартиры в\u00a0новом ЖК',
    block_cases_house_before_1: 'Только два ракурса внешнего вида жилого комплекса',
    block_cases_house_before_2: 'Трудно определить окна конкретной квартиры',
    block_cases_house_before_3: 'Сложно понять вид из окон',
    block_cases_house_after_1: 'Вращение и\u00a0масштабирование дома позволяет намного полнее оценить изыски архитектуры, чем статичная картинка',
    block_cases_house_after_2: 'Благодаря возможности свободно менять ракурс вида на\u00a0дом, можно легко определить окна у\u00a0любой квартиры',
    block_cases_house_after_3: 'Интерактивная карта окружения дает возможность визуально оценить вид из\u00a0окон',
    block_cases_house_result: 'Среднее время пребывания на\u00a0странице проекта увеличилось в\u00a02.4 раза.',
    block_cases_furniture: '3D-обзор мебели',
    block_cases_furniture_task: 'Дать покупателям возможность примерять мебель в своем интерьере, не выходя из дома',
    block_cases_furniture_before: 'Статичное изображение продукта',
    block_cases_furniture_after: 'Интерактивная 3D-модель товара с возможностью примерки в реальном окружении',
    block_cases_furniture_result: 'Число заявок с сайта увеличилось на 8%',
    block_cases_sloros: 'Загрузка на сайт просмотра большого числа 3D-моделей',
    block_cases_sloros_task: 'Внедрить в маркетплейс 3D-виджеты для просмотра в трехмерном пространстве более 1000\u00a0товаров',
    block_cases_sloros_before: 'Стандартные изображения',
    block_cases_sloros_after: 'Интерактивный 3D-просмотр',
    block_cases_sloros_result: 'Успешно внедрили на сайт модели клиента в течение недели.',
    block_cases_kaflon: '3D-обзор электромагнитных расходомеров',
    block_cases_kaflon_task: 'Демонстрация изделий в 3D',
    block_cases_kaflon_before: 'Только фотографии продукции',
    block_cases_kaflon_after: '3D-просмотр с возможностью поделиться ссылкой',
    block_cases_kaflon_result: 'После месяца внедрения 3D-виджета продажи выросли на 16%.',
    block_cases_madkit: '3D-конструктор огнетушителя',
    block_cases_madkit_task: 'Создать интерактивный 3D-виджет огнетушителя с возможностью смены цвета корпуса и наложения на него пользовательской картинки',
    block_cases_madkit_before: 'Только фотографии и видео',
    block_cases_madkit_after: 'Добавился интерактивный 3D-конфигуратор огнетушителя',
    block_cases_madkit_result: 'Вовлеченность в продукт на сайте возросла на 23%',
    block_cases_metalwork: '3D-обзор металлоконструкций',
    block_cases_metalwork_task: 'Увеличить число клиентов на\u00a0производство траверс',
    block_cases_metalwork_before: 'Страницы продукта содержали статичные картинки и\u00a0описание плюс минус как и\u00a0у конкурентов. Заявки были крайне редки.',
    block_cases_metalwork_after: 'Внедрили в\u00a0карточки интерактивные 3D-виджеты изделий с кликабельным описанием основных преимуществ компании.',
    block_cases_metalwork_result: 'После месяца внедрения 3D-виджета продажи траверс возросли на 37%.',
    block_cases_mishki: 'Кейс 0+ Медиа',
    block_cases_mishki_task: 'Внедрить интерактивные 3D обкатки героев мультсериалов в сайт для партнеров для наглядности',
    block_cases_mishki_before: '2D картинки поз персонажей',
    block_cases_mishki_after: '3D-виджеты героев',
    block_cases_mishki_result: 'Проще стало доносить образы мультяшных персонажей нашим партнерам.',
    block_cases_mockup: '3D-конфигуратор мокапа',
    block_cases_mockup_task: 'Дать клиентам дизайнера возможность просмотра различных вариантов мокапа с помощью интерактивного 3D.',
    block_cases_mockup_before: '2D отображение мокапа',
    block_cases_mockup_after: '3D отображение мокапа',
    block_cases_mockup_result: 'Время демонстрации работы с помощью 3D-виджета сократилось вдвое.',
    block_cases_menu: '3D-меню для сайта',
    block_cases_menu_task: 'Заменить скучный слайдер основных разделов сайта на интерактивную 3D-модель, выполняющую роль навигационного меню',
    block_cases_menu_before: 'Обычный слайдер',
    block_cases_menu_after: '3D-виджет',
    block_cases_menu_result: 'Средняя вовлеченность пользователей возросла на 40%.',
    block_cases_more: 'Еще кейс',
    block_cases_all: 'Все кейсы',
    block_team_caption: 'Наша команда',
    block_team_description: 'Делаем 3D технологии доступными любому бизнесу',
    block_team_owner_name: 'Алексей Ткаченко',
    block_team_owner_post: 'Основатель и разработчик',
    block_team_gruzdev_name: 'Алексей Груздев',
    block_team_gruzdev_post: '3D дизайнер',
    block_clients_caption: 'Наши клиенты',
    block_clients_description: 'Нам доверяют компании из разных отраслей бизнеса',
    block_clients_0plusmedia_caption: '0+ медиа',
    block_clients_sloros_caption: 'Слорос',
    block_clients_lesenka_caption: 'Лесенка',
    block_clients_kgorod_caption: 'Красивый город',
    block_clients_architent_caption: 'ArchiTent',
    block_clients_madkit_caption: 'Madkit',
    block_clients_glode_caption: 'Glode',
    block_clients_vesservice_caption: 'Невские весы',
    block_clients_kkkm_caption: 'Красноярский краеведческий музей',
    block_clients_arbatskazka_caption: 'Арбатская сказка',
    block_clients_kaflon_caption: 'Kaflon',
    block_clients_istok_caption: 'Исток',
    block_clients_darexhouse_caption: 'Darex House',
    block_clients_artxpro_caption: 'ARTxPRO',
    block_clients_gorodovoy_caption: 'Городовой',
    block_clients_bestcorian_caption: 'BestCorian',
    block_clients_consultbearings_caption: 'Консалт Bearings',
    block_clients_berucottage_caption: 'Берукоттедж',
    block_reviews_caption: 'Отзывы',
    block_reviews_description: 'Наша миссия - помогать бизнесам расти в прибыли',
    block_reviews_lesenka_name: 'Егор Котов',
    block_reviews_lesenka_post: '«Лесенка»',
    block_reviews_lesenka_text: 'Пришла идея разместить на сайте 3D модели лестниц, остановились на Site3D Configurator. Понравилось, что много настроек. Техподдержка на высоком уровне, не только помогла с настройками, но и подсказала, как подготовить 3D модели. Встраиваться очень легко на сайт. Плюс, есть демо период, чтобы все протестировать и проверить. Рекомендую.',
    block_reviews_0plusmedia_name: 'Ника',
    block_reviews_0plusmedia_post: 'Бренд-менеджер 0+ Медиа',
    block_reviews_0plusmedia_text: 'Сервис очень удобен в использовании, можно самостоятельно настроить параметры сцены 3D фигурки, не будучи специалистом. Все бесшовно внедряется в сайт на Тильде.',
    block_reviews_namero_name: 'Иван',
    block_reviews_namero_post: 'Основатель Namero Design',
    block_reviews_namero_text: 'У вас классный инструмент, спасибо. Давно искал что-то подобное. На все вопросы ответили подробно и доходчиво.',
    block_reviews_stendi_name: 'Валерий',
    block_reviews_stendi_post: 'Проект «Умные стенды»',
    block_reviews_stendi_text: 'Сервис 10 из 10, активно используем его в работе, очень довольны обслуживанием и технической поддержкой данного сервиса!',
    block_reviews_royalcourt_name: 'Тамерлан',
    block_reviews_royalcourt_post: 'Агентство недвижимости «Роял Корт»',
    block_reviews_royalcourt_text: 'Понравился сервис! Очень лояльно, современно и качественно отработали. Можно визуально ознакомиться на royalcourt.ru.',
    block_reviews_rapts_name: 'Альгис',
    block_reviews_rapts_post: 'Директор департамента цифровых технологий Ассоциации производителей трубопроводных систем',
    block_reviews_rapts_text: 'Отличный русскоязычный сервис. По функционалу есть еще что дорабатывать, но тех. поддержка на высоте.',
    block_reviews_en_komplekt_name: 'Дмитрий',
    block_reviews_en_komplekt_post: '«Энерго-комплект СПБ»',
    block_reviews_en_komplekt_text: 'Обратились к данному сервису по совету нашего системного администратора. Макет заказа сделали быстро. 3D-модель работает на ура на сайте. Рекомендую к обращению.',
    block_reviews_more: 'Больше отзывов',
    block_reviews_other: '<p>Отзывы на сторонних площадках:</p>' +
      '<ul>' +
      '<li><a href="https://yandex.ru/profile/77603470901" target="_blank">Яндекс</a></li>' +
      '<li><a href="https://2gis.ru/spb/firm/70000001077787190" target="_blank">2ГИС</a></li>' +
      '</ul>',
    block_quality_caption: 'Реалистиная графика',
    block_quality_description: 'Максимальное погружение в ваш продукт',
    block_ar_caption: 'Дополненная реальность',
    block_ar_description: 'Переносите 3D в реальное окружение',
    block_cost_caption: 'Тарифы',
    block_cost_description: 'Безлимитные возможности по антикризисным ценам',
    block_cost_individual_caption: 'Не устраивают тарифы или хотите внедрение 3D на сайт под ключ?',
    block_cost_individual_button: 'Подобрать индивидуальное решение',
    block_cost_individual_link: 'https://b24-chbx01.bitrix24.site/crm_form_hx5gm',
    block_development_caption: 'Нужен нестандартный 3D-конфигуратор?',
    block_development_description: 'Реализуем любую вашу идею',
    block_development_text: '<p>Напишите нам <a href="/support">в поддержку</a> для обсуждения индивидуального решения</p>',
    block_contacts_caption: 'Свяжитесь с нами',
    block_contacts_description: 'Мы на связи ежедневно с 9:00 до 19:00 по московскому времени',
    block_seo_text: '' +
      '<article class="article">' +
      '<section>' +
      '<p>Познакомьтесь с презентациями нашего сервиса для отдельных отраслей:</p>' +
      '<ul>' +
      '<li><a href="/houses">Дома</a></li>' +
      '<li><a href="/pavilions">Беседки</a></li>' +
      '<li><a href="/stairs">Лестницы</a></li>' +
      '<li><a href="/playgrounds">Детские площадки</a></li>' +
      '<li><a href="/fireplaces">Камины</a></li>' +
      '<li><a href="/industry">Промышленное оборудование</a></li>' +
      '</ul>' +
      '</section>' +
      '</article>',
    branding: 'Брендирование',
    branding_description: '<p>Для масштабного изменения внешнего вида 3D-виджета под стиль вашей компании поместите в zip-архив файл стилей style.css и необходимые графические файлы (для них можно использовать любую вложенность каталогов).</p>' +
      '<p>Рекомендуем посмотреть <a href="https://configurator.site3d.site/docs/branding.zip" target="_blank">пример</a> с комментариями.</p>' +
      '<p>За более детальной информацией просьба обратиться <a href="/support">в поддержку</a>.</p>',
    branding_saving: 'Загрузка брендирования',
    channels_telegram_url: 'https://t.me/site3d_configurator',
    channels_telegram_title: 'Телеграм',
    channels_vk_url: 'https://vk.com/site3d_configurator',
    channels_vk_title: 'ВКонтакте',
    channels_zen_url: 'https://zen.yandex.ru/id/5d0f8b71ef7fdf00afad5234',
    channels_zen_title: 'Яндекс Дзен',
    channels_rutube_url: 'https://rutube.ru/channel/24348974',
    channels_rutube_title: 'RuTube',
    channels_youtube_url: 'https://www.youtube.com/@site3d_configurator',
    channels_youtube_title: 'YouTube',
    code: 'Вставить на сайт',
    code_id: 'Идентификатор проекта: {id}',
    code_any: 'Для любого сайта',
    code_domain: 'Домены (без http, www и слешей)',
    code_domain_hint: 'Проект будет загружаться только на\u00a0ваших доменах. Их нужно указывать через запятую.',
    code_height: 'Высота виджета',
    code_height_hint: 'По умолчанию высота виджета 500\u00a0пикселей. Его можно растянуть на всю ширину контейнера (100%), внутри которого вы разместите виджет. Также можно указать иное значение высоты в\u00a0пикселях.',
    code_height_default: 'По умолчанию',
    code_height_percent: '100%',
    code_height_pixel: 'Задать',
    code_container: 'Контейнер для виджета',
    code_container_hint: 'Контейнеров для виджетов может быть несколько.',
    code_container_type_1: 'Стандарт',
    code_container_type_2: 'С кодом товара',
    code_container_type_2_warning: 'Необходимо на первом шаге указать код товара',
    code_script: 'Скрипт для загрузки виджетов',
    code_script_hint: 'Скрипт должен быть только один на\u00a0странице и\u00a0находиться после всех контейнеров для виджетов.',
    code_copy: 'Скопировать код',
    code_copy_all: 'Скопировать весь код',
    code_copy_all_hint: 'Если планируется только один виджет на\u00a0странице.',
    code_plugin_link: 'Ссылка на\u00a0плагин',
    code_component_link: 'Ссылка на\u00a0компонент',
    code_copy_success: 'Код успешно скопирован в\u00a0буфер обмена',
    code_wordpress: 'Для Wordpress CMS',
    code_wordpress_copy: 'Скопировать шорткод',
    code_moguta: 'Для Moguta CMS',
    code_creatium: 'Для Creatium',
    code_link: 'Ссылка на просмотр',
    code_link_loading: 'Загрузка данных для просмотра …',
    code_link_text: 'Ссылка на\u00a0просмотр проекта',
    code_link_update: 'Обновить доступ по ссылке',
    code_link_update_confirm: 'Действительно обновить доступ по ссылке? Предыдущие ссылки перестанут работать.',
    code_link_update_success: 'Доступ по ссылке успешно обновлен',
    code_link_copy: 'Скопировать ссылку',
    code_link_copy_success: 'Ссылка успешно скопирована в\u00a0буфер обмена',
    commerce_caption: 'Расширить возможности',
    commerce_description: 'Для коммерческого использования сервиса нужно <a href="/buy">приобрести один из платных тарифов</a>',
    common_settings: 'Общие настройки',
    configurator_caption: 'Название проекта',
    configurator_caption_hint: 'Максимум 200 символов',
    configurator_caption_init: 'Мой проект',
    configurator_category: 'Категория',
    configurator_categories_caption: 'Категории',
    configurator_categories_default: 'Без категории',
    configurator_categories_empty: 'Категорий нет',
    configurator_categories_remove_confirm: 'Действительно хотите удалить эту категорию?',
    configurator_categories_edit_caption: 'Название категории',
    configurator_clone_confirm: 'Действительно хотите создать копию данного проекта?',
    configurator_domains_caption: 'Домены',
    configurator_domains_default: 'Без домена',
    configurator_domains_empty: 'Домены еще не добавлены',
    configurator_domains_remove_confirm: 'Действительно хотите удалить этот домен?',
    configurator_domains_edit_caption: 'Домен (без http, www и слешей)',
    configurator_list_caption: 'Проекты',
    configurator_list_empty: 'У вас еще нет созданных проектов',
    configurator_list_remove_confirm: 'Действительно хотите удалить проект <span class="hot">{name}</span>?',
    configurator_list_clone_confirm: 'Действительно хотите создать копию проекта <span class="hot">{name}</span>?',
    configurator_list_return: 'К списку',
    configurator_material_tags_caption: 'Теги материалов',
    configurator_material_tags_default: 'Без тегов',
    configurator_material_tags_empty: 'Тегов материалов нет',
    configurator_material_tags_remove_confirm: 'Действительно хотите удалить этот тег материалов?',
    configurator_optimizing_read: 'Чтение файла модели',
    configurator_optimizing_prepare: 'Подготовка модели для 3D сцены',
    configurator_optimizing_save: 'Сохранение модели в оптимизированном виде',
    configurator_saving: 'Сохранение проекта',
    configurator_scene_to_other_description: 'Внимание! Параметры сцены текущего проекта будут применены ко всем вашим проектам. Перед началом необходимо сохранить все внесенные изменения в данном проекте.',
    configurator_scene_to_other_items: 'Копируемые настройки',
    configurator_scene_to_other_confirm: 'Действительно применить параметры сцены для других проектов?',
    configurators_edit: 'Редактировать проекты',
    configurators_saving: 'Сохранение проектов',
    configurators_export_letter_dealer: 'Письмо дилеру',
    configurators_export_letter_dealer_caption: 'Увеличьте продажи с помощью 3D-моделей',
    configurators_export_letter_dealer_text: '<p>Уважаемый дилер, предлагаем внедрить на Ваш сайт 3D-модели нашей продукции.</p>' +
      '<p>О пользе, этапах и стоимости внедрения читайте здесь: <a href="https://configurator.site3d.site/docs/dealer_ru.pdf" target="_blank">https://configurator.site3d.site/docs/dealer_ru.pdf</a>.</p>',
    configurators_export_instruction: 'Инструкция для внедрения на сайт',
    configurators_export_instruction_id: 'ID проекта',
    configurators_export_instruction_clientCode: 'Код товара',
    configurators_export_instruction_container_code: 'Код контейнера для вставки на сайт',
    configurators_export_instruction_script_code: 'Код скрипта для вставки на сайт перед закрывающим тегом body',
    configurators_export_instruction_link: 'Ссылка на просмотр в отдельном окне браузера',
    contacts: '<div class="contacts__block">' +
      '<h3 class="contacts__blockCaption">Офлайн</h3>' +
      '<div class="contacts__blockContent">' +
      '<p>197022, г. Санкт-Петербург, Проспект Медиков, дом 3, лит. А</p>' +
      '<p><span class="hot">по предварительному согласованию</span></p>' +
      '</div>' +
      '</div>' +
      '<div class="contacts__block">' +
      '<h3 class="contacts__blockCaption">Онлайн</h3>' +
      '<div class="contacts__blockContent">' +
      '<p>Контакты для любых вопросов:</p>' +
      '<p><a class="contacts__blockItem contacts__blockItem_email" href="mailto:info@site3d.site">info@site3d.site</a></p>' +
      '<p><a class="contacts__blockItem contacts__blockItem_telegram" href="https://t.me/site3d_support" target="_blank">@site3d_support</a></p>' +
      '</div>' +
      '</div>',
    copy_id: 'Скопировать идентификатор проекта',
    copy_id_success: 'Идентификатор проекта успешно скопирован в\u00a0буфер обмена',
    design: 'Оформление',
    design_theme: 'Тема',
    design_theme_default: 'Site3D',
    design_theme_lite: 'Лайт',
    design_appearance: 'Стилизация',
    design_appearance_apply_to_other_confirm: 'Действительно применить стилизацию к другим проектам (требуется предварительно сохранить проект)?',
    design_font_family: 'Шрифт',
    design_border_radius: 'Скругление углов виджета',
    design_background_color: 'Цвет сцены',
    design_preload: 'Перед стартом',
    design_preload_background_color: 'Цвет фона',
    design_preload_background_image: 'Фоновое изображение',
    design_preload_poster: 'Кнопка загрузки в виде картинки',
    design_preload_button: 'Стандартная кнопка загрузки',
    design_logo: 'Логотип сервиса',
    design_logo_hint: 'Редактирование логотипа сервиса не доступно на бесплатном тарифе',
    design_logo_is_enabled: 'Показать',
    design_logo_enabled_time: 'Время появления',
    design_logo_enabled_time_start: 'При старте',
    design_logo_enabled_time_hover: 'При наведении',
    design_watermark: 'Водяной знак',
    docs_user_agreement: 'Пользовательское соглашение',
    docs_public_offer: 'Публичная оферта',
    docs_telegram: 'Набор <a href="https://t.me/addstickers/Site3dConfigurator" target="_blank">стикеров</a> и <a href="https://t.me/addstickers/Site3dConfiguratorEmoji" target="_blank">эмодзи</a> в телеграме',
    export_image: 'Изображение',
    export_image_no_widget: 'Перед созданием изображения необходимо загрузить 3D-виджет',
    export_video: 'Видео',
    export_video_no_widget: 'Перед созданием видео необходимо загрузить 3D-виджет',
    export_video_duration: 'Продолжительность ролика',
    file_load: 'Загрузка файла',
    file_wrong_format: 'Допустимые форматы: {formats}',
    file_wrong_model_size: 'Сейчас максимально допустимый размер модели: {maxSize}\u00a0Мб. Вы можете увеличить это значение, написав нам <a href="/support">в поддержку</a>. Также мы можем помочь в оптимизации вашей модели, чтобы уменьшить её размер.',
    file_wrong_texture_size: 'Максимально допустимый размер текстуры: {maxSize}\u00a0Мб. Оптимальное разрешение текстуры для большинства случаев 1024 пикселей по длинной стороне. Рекомендуем сохранять текстуру со сжатием в формате jpg.',
    footer_reestr: 'Включены в реестр Российского ПО',
    footer_help: 'Поддержка',
    footer_help_guide: 'Инструкция',
    footer_channels: 'Наши каналы',
    form_need_fill_inputs: 'Не заполнены все обязательные поля',
    form_step: 'Шаг',
    form_step_model: 'Загрузка модели',
    form_step_scene: 'Параметры сцены',
    form_step_parts: 'Части модели',
    form_step_export: 'Экспорт',
    form_save: 'Сохранить',
    graphics_quality: 'Качество',
    graphics_alpha: 'Прозрачность',
    graphics_bloom: 'Свечение',
    graphics_bloom_strength: 'Сила',
    graphics_bloom_radius: 'Радиус',
    graphics_bloom_threshold: 'Порог',
    graphics_color: 'Цветовой баланс',
    graphics_color_r: 'Красный',
    graphics_color_g: 'Зеленый',
    graphics_color_b: 'Синий',
    graphics_brightness: 'Яркость',
    graphics_contrast: 'Контраст',
    graphics_shadows: 'Тени',
    graphics_optimize: 'Оптимизировать модель',
    graphics_screen_size_correction: 'Скрывать мелкие части модели',
    graphics_auto_correction: 'Автокоррекция',
    graphics_auto_correction_hint: 'Сервис будет занижать качество графики для слабых устройств с целью поддержания оптимальной скорости отклика на действия пользователя.',
    graphics_common_material: 'Общий материал модели',
    graphics_edges: 'Грани',
    graphics_edges_hint: 'Для корректного отображения граней рекомендуем следовать нашим советам при подготовке модели (смотрите <a href="/docs/guide_ru.pdf" target="_blank">наше руководство</a>)',
    header_try: 'Попробуйте бесплатно',
    help_telegram_url: 'https://t.me/site3d_support',
    help_telegram_title: 'Телеграм',
    help_email: 'info@site3d.site',
    help_email_url: 'mailto:info@site3d.site',
    help_email_title: 'Эл. почта',
    hint_subdomains: '<p>У вашего сайта есть доменное имя, например, site.ru. Вы можете создать сколько угодно сайтов в виде поддоменов. Например, page1.site.ru, page2.site.ru и т.д.</p>',
    hint_ar: '<p>С помощью технологии дополненной реальности вы можете прямо с сайта примерить товар в реальном окружении. Например, можете посмотреть, как будет смотреться диван в вашей комнате.</p>',
    hint_mockups: '<p>С помощью нашего сервиса можно автоматически создавать мокапы в виде 2D-изображений с прозрачным фоном или видеороликов. Таким образом вы сможете дополнить карточки ваших товаров не только интерактивным 3D-просмотром, но и разнообразными картинками и видео-обзорами.</p>',
    hint_scene_to_other: '<p>У вас есть возможность настраивать просмотр сразу для большого числа ваших проектов, чтобы ваши 3D-виджеты были в одном стиле.</p>',
    hint_configuring: '<p>Конфигурирование включает в себя:</p>' +
      '<ul>' +
      '<li>Смена цветов, текстур и комплектаций</li>' +
      '<li>Всплывающие подсказки и ссылки, привязанные к определенными частям товара</li>' +
      '<li>Расчет стоимости и форма заказа</li>' +
      '</ul>',
    hint_mass_editing: '<p>Если у вас много товаров, то можно поместить все 3D-модели в архив и автоматически создать множество проектов для вставки их на сайт</p>' +
      '<p>В рамках тарифа можно загружать до 1000 товаров. Возможность автоматически добавить дополнительно 1000 проектов — 10&nbsp;000&nbsp;₽ в&nbsp;год.</p>',
    hint_branding: '<p>Брендирование &mdash; создание индивидуальной темы оформления виджетов согласно вашему фирменному стилю:</p>' +
      '<ul>' +
      '<li>Замена внешнего вида процесса загрузки</li>' +
      '<li>Замена иконок управляющих элементов</li>' +
      '<li>Другие изменения в пользовательском интерфейсе по согласованию</li>' +
      '</ul>',
    hint_priority_support: '<p>Приоритетная поддержка включает в себя:</p>' +
      '<ul>' +
      '<li>Обучение ваших дизайнеров подготовке 3D-моделей</li>' +
      '<li>Обучение ваших программистов внедрению 3D-моделей в сайт</li>' +
      '</ul>',
    input_tags_placeholder: 'Для добавления используйте запятую или ввод',
    materials_edit: 'Каталог общих материалов',
    materials_saving: 'Сохранение материалов',
    materials_common: 'Общий материал',
    materials_global: 'Материал из библиотеки',
    materials_item_selected: 'Выбран материал: <span class="hot">{name}</span>',
    material_type_texture_maps: 'На текстуры и карты',
    material_type_only_texture: 'Только на текстуры',
    menu_main_caption: 'Главная',
    menu_cases_caption: 'Кейсы',
    menu_reviews_caption: 'Отзывы',
    menu_news_caption: 'Новости',
    menu_posts_caption: 'Блог',
    menu_faq_caption: 'Частые вопросы',
    menu_account_caption: 'Кабинет',
    menu_buy_caption: 'Тарифы',
    menu_partners_caption: 'Партнерам',
    menu_api_caption: 'Разработчикам',
    menu_support_caption: 'Поддержка',
    model_file: 'Модель',
    model_file_hint: '<p>Для создания или поиска готовой модели рекомендуем использовать формат <span class="hot">glb</span>.</p>' +
      '<p>Если формат вашей модели не поддерживается нашим сервисом, вы можете:</p>' +
      '<ul>' +
      '<li>Экспортировать в привычном 3D-редакторе <a href="/docs/guide_ru.pdf" target="_blank">по нашей инструкции</a></li>' +
      '<li>Обратиться в нашу <a href="/support" target="_blank">поддержку</a>, мы поможем вам с конвертацией</li>' +
      '</ul>' +
      '<p>Если загружаемый файл модели требует вспомогательные файлы (например, текстуры), необходимо запаковать все файлы в <span class="hot">zip архив</span>.</p>' +
      '<p>Также для улучшения скорости просмотра в архив можно вложить более детализированные версии модели целиком или только её отдельных элементов.</p>' +
      '<p>Данные файлы необходимо именовать таким образом, чтобы после сортировки они следовали после основного файла модели и далее от менее детализированных версий к более детализированным.</p>' +
      '<p>Такие модели будут загружаться только при приближении камеры к первоначальной модели.</p>',
    model_file_catalog_hint: 'Если не найдется нужного варианта, то мы можем бесплатно добавить его по запросу <a href="/support">в поддержку</a>.',
    model_file_info_caption: 'О модели',
    model_file_info_triangles: 'Полигоны: <span class="hot">{value}</span>',
    model_file_info_parts_all: 'Все элементы: <span class="hot">{value}</span>',
    model_file_info_parts_unique: 'Уникальные элементы: <span class="hot">{value}</span>',
    model_file_settings: 'Настройки загрузки модели',
    model_file_convert: 'Сохранить в glb',
    model_file_optimize: 'Оптимизировать',
    model_file_auto_center: 'Центрировать',
    model_file_encrypt: 'Шифровать',
    model_file_encrypt_hint: 'Шифрование защищает модель от скачивания, но увеличивает вес модели и время загрузки',
    model_file_compress: 'Сжимать',
    model_file_compress_hint: 'В редких случаях сжатие может привести к ошибочному отображению модели. В этом случае необходимо отключить его перед отправкой файла модели.',
    model_file_unexpected: 'Если вы ожидали другого результата, отключите все пункты из настроек загрузки модели (находятся выше) и повторно загрузите свою 3D-модель',
    model_site3d: 'Логотип',
    model_house: 'Дом',
    move_camera_is_enabled: 'Включить',
    move_camera_pos_from_model: 'Стартовый вид',
    move_camera_pos_from_model_hint: 'Дистанция до части модели в\u00a0% и\u00a0повороты',
    move_camera_model_person: 'Человек по отношению к метке',
    move_camera_scale_range: 'Диапазон масштабирования',
    move_camera_rotate_range: 'Углы обзора',
    move_camera_rotate_range_hint: 'Свобода обзора при перемещении к метке',
    move_camera_use_target: 'Вращать вокруг модели',
    move_camera_open_window: 'Открыть окно после перемещения',
    move_camera_anim_variant: 'Проиграть вариант анимации',
    news_not_found: 'Нет новостей',
    news_loading: 'Загружается раздел новостей',
    news_content_not_found: 'Новость не найдена',
    news_content_loading: 'Загружается новость',
    news_content_all: 'Все новости',
    order_info: 'Форма заказа',
    order_info_is_visible: 'Показать',
    parts_hover: 'Название выделенной части модели <span class="hot">{name}</span>',
    parts_hint: 'Кликните по части модели, для которой нужно указать цвета, материалы или метку (обрабатываются <span class="hot">{count}</span> самых объемных частей).',
    parts_no_hint: 'У модели нет отдельных частей',
    parts_settings_caption: 'Настройки',
    parts_settings_groups_empty: 'Группы еще не добавлены',
    parts_settings_groups_default: 'Без группы',
    parts_settings_groups_remove_confirm: 'Действительно хотите удалить эту группу?',
    parts_settings_empty: 'Настройки еще не добавлены',
    parts_settings_exist: 'Такая настройка для данной части модели уже существует',
    parts_settings_many_materials: 'Для редактирования цвета или материала у части модели должен быть только один материал',
    parts_settings_colors_add: 'Добавить цвета',
    parts_settings_colors_edit: 'Редактировать цвета',
    parts_settings_colors_elements_values: 'Список',
    parts_settings_colors_elements_palette: 'Палитра',
    parts_settings_colors_elements_values_palette: 'Список и\u00a0палитра',
    parts_settings_colors_empty: 'Цвета еще не добавлены',
    parts_settings_colors_value_exist: 'Такой цвет уже добавлен',
    parts_settings_colors_remove_confirm: 'Действительно хотите удалить цвет?',
    parts_settings_materials_add: 'Добавить материалы',
    parts_settings_materials_edit: 'Редактировать материалы',
    parts_settings_materials_empty: 'Материалы еще не добавлены',
    parts_settings_materials_remove_confirm: 'Действительно хотите удалить материал?',
    parts_settings_models_add: 'Добавить модели',
    parts_settings_models_edit: 'Редактировать модели',
    parts_settings_models_empty: 'Модели еще не добавлены',
    parts_settings_models_remove_confirm: 'Действительно хотите удалить модель?',
    parts_settings_models_part_hint: 'Здесь можно указать имена частей модели, которые будут показываться при выборе данного варианта. При этом, части моделей, имена которых указаны в данном поле у других вариантов, будут скрыты.',
    parts_settings_toggle_add: 'Добавить переключатель видимости',
    parts_settings_toggle_edit: 'Редактировать переключатель видимости',
    parts_settings_toggle_value: 'Показать',
    parts_settings_size_add: 'Добавить размеры',
    parts_settings_size_edit: 'Редактировать размеры',
    parts_settings_remove_confirm: 'Действительно хотите удалить настройку?',
    parts_select: 'Выберите действие над частью модели <span class="hot">{name}</span>',
    parts_labels: 'Метки',
    parts_labels_hint: 'Более высокие метки в списке перекрывают видимость более низких меток.',
    parts_labels_empty: 'Метки еще не добавлены',
    parts_labels_add: 'Добавить метку',
    parts_labels_edit: 'Редактировать метку',
    parts_labels_auto_start: 'Показать при старте',
    parts_labels_button_text: 'Текст кнопки',
    parts_labels_button_text_hint: 'Вместо пустого текста будет иконка',
    parts_labels_link: 'Ссылка',
    parts_labels_link_url: 'URL',
    parts_labels_link_text: 'Текст',
    parts_labels_pos: 'Позиция',
    parts_labels_align: 'Выравнивание',
    parts_labels_align_button: 'Кнопка',
    parts_labels_align_window: 'Окно',
    parts_labels_move_camera: 'Перемещение к метке',
    parts_labels_common_styles: 'Общие настройки стилей',
    parts_labels_styles_button: 'Начальный вид',
    parts_labels_styles_window: 'Окно',
    parts_labels_styles_window_main: 'Главные',
    parts_labels_styles_window_caption: 'Заголовок',
    parts_labels_styles_window_text: 'Текст',
    parts_labels_styles_window_link: 'Ссылка',
    parts_labels_remove_confirm: 'Действительно хотите удалить метку?',
    parts_links: 'Ссылки',
    parts_links_empty: 'Ссылки еще не добавлены',
    parts_links_add: 'Добавить ссылку',
    parts_links_edit: 'Редактировать ссылку',
    parts_links_url: 'URL',
    parts_links_hint: 'Подсказка',
    parts_links_selection: 'Выделение',
    parts_links_selection_common_selection: 'Общие настройки выделения',
    parts_links_selection_color: 'Цвет',
    parts_links_selection_thickness: 'Толщина',
    parts_links_styles_hint: 'Подсказка',
    parts_links_remove_confirm: 'Действительно хотите удалить ссылку?',
    page_main_caption: 'Site3D Configurator',
    page_main_description: 'Сервис быстрого создания 3D-конфигураторов',
    page_main_title: 'Site3D Configurator - показать 3D-модель или конфигуратор на сайте',
    page_main_meta_description: 'Сервис позволяет встраивать в сайт интерактивный виджет с 3D-моделью товара или услуги с поддержкой возможности конфигурирования (смена цветов, текстур, замена одних частей модели на другие, проигрывание анимации и многое другое).',
    page_cases_caption: 'Наши кейсы',
    page_cases_description: 'Приносим пользу бизнесу. Расширяем границы возможностей.',
    page_cases_title: 'Site3D Configurator - Кейсы',
    page_cases_meta_description: 'Примеры использования онлайн конфигуратора 3D-моделей Site3D Configurator (смена цветов, текстур, замена одних частей модели на другие, проигрывание анимации и многое другое).',
    page_reviews_caption: 'Отзывы',
    page_reviews_description: 'Наша миссия - помогать бизнесам расти в прибыли',
    page_reviews_title: 'Site3D Configurator - Отзывы',
    page_reviews_meta_description: 'Отзывы на онлайн конфигуратор 3D-моделей Site3D Configurator (смена цветов, текстур, замена одних частей модели на другие, проигрывание анимации и многое другое).',
    page_news_caption: 'Новости',
    page_news_description: 'Открываем новые возможности вашего бизнеса',
    page_news_title: 'Site3D Configurator - Новости',
    page_news_meta_description: 'Новости облачного сервиса Site3D Configurator.',
    page_posts_caption: 'Блог',
    page_posts_description: 'Все самое интересное для внедрения 3D в ваш бизнес',
    page_posts_title: 'Site3D Configurator - Блог',
    page_posts_meta_description: 'Блог облачного сервиса Site3D Configurator.',
    page_faq_caption: 'Часто задаваемые вопросы',
    page_faq_description: 'Собрали для вас ответы на самые популярные вопросы в одном месте',
    page_faq_title: 'Site3D Configurator - Частые вопросы',
    page_faq_meta_description: 'Часто задаваемые вопросы облачного сервиса Site3D Configurator.',
    page_account_caption: 'Личный кабинет',
    page_account_button_login: 'Войти / Регистрация',
    page_account_button_enter: 'Мой кабинет',
    page_profile_caption: 'Профиль',
    page_buy_caption: 'Оплата подписки',
    page_buy_success: '<p>Поздравляем! Оплата прошла успешно. В течение суток после поступления платежа ваш тариф будет активирован.</p><p>Если вы оплатили подписку без авторизации, пожалуйста, напишите нам в поддержку, чтобы мы привязали вашу оплату к вашему аккаунту.</p><p><a href="/buy">Оплатить еще один тариф</a></p>',
    page_buy_fail: '<p>К сожалению, оплата не прошла. Пожалуйста, <a href="/buy">попробуйте еще раз</a> или напишите нам в <a href="/support">поддержку</a>.</p>',
    page_partners_caption: 'Партнерам',
    page_partners_description: 'Зарабатывайте на новейших технологиях',
    page_partners_content:
      '<div class="textBlocks">' +
      '<div class="textBlocks__item">' +
      '<h2>Партнерская программа</h2>' +
      '<h3>Для кого программа?</h3>' +
      '<ul>' +
      '  <li>Веб-студии и интернет-агентства</li>' +
      '  <li>Фрилансеры, оказывающие услуги по разработке сайтов и веб-дизайна</li>' +
      '</ul>' +
      '<h3>Что вы получите?</h3>' +
      '<ul>' +
      '  <li>50% со всех покупок нашего сервиса вашими клиентами</li>' +
      '  <li>100% с внедрения сервиса на сайты ваших клиентов (расширите спектр своих услуг)</li>' +
      '  <li>Отсутствие логотипа во время загрузки виджета</li>' +
      '</ul>' +
      '<h3>Как стать партнером?</h3>' +
      '<ul>' +
      '  <li><a href="/reg">Зарегистрируйтесь</a> в нашем сервисе</li>' +
      '  <li>Приведите первого клиента и напишите нам <a href="/support">в поддержку</a></li>' +
      '</ul>' +
      '<h3>Как действовать после подтверждения партнерства?</h3>' +
      '<ul>' +
      '  <li>Получаете с клиента 100% оплату подписки по ценам, указанных в <a href="/buy">разделе оплаты</a></li>' +
      '  <li>Авторизуетесь в нашем сервисе, заходите на <a href="/buy">страницу оплаты</a> и оплачиваете подписку для клиента с 50% скидкой</li>' +
      '  <li>Размещаете проекты на сайте клиента</li>' +
      '</ul>' +
      '</div>' +
      '<div class="textBlocks__item">' +
      '<h2>Реферальная программа</h2>' +
      '<h3>Для кого программа?</h3>' +
      '<p>Любой желающий заработать на привлечении клиентов в наш сервис.</p>' +
      '<h3>Что вы получите?</h3>' +
      '<ul>' +
      '  <li>50% с годовой подписки нашего клиента</li>' +
      '  <li>Дополнительных клиентов за счет размещения информации о ваших услугах в нашем сервисе</li>' +
      '</ul>' +
      '<h3>Как начать зарабатывать?</h3>' +
      '<ul>' +
      '  <li><a href="/reg">Регистрируетесь</a> в нашем сервисе</li>' +
      '  <li>В профиле пользователя копируете реферальную ссылку</li>' +
      '  <li>Размещаете ссылку рядом с описанием своих услуг на своих площадках</li>' +
      '  <li>После оплаты подписки перешедшего по вашей ссылке клиента мы связываемся с вами и перечисляем вам вознаграждение удобным для вас способом</li>' +
      '</ul>' +
      '</div>' +
      '</div>',
    page_api_caption: 'Разработчикам',
    page_api_description: 'Делайте более тесные взаимодействия нашего 3D-виджета с вашим бизнесом',
    page_support_caption: 'Поддержка',
    page_support_description: 'Оперативно решим задачу по внедрению интерактивного 3D на ваш сайт',
    page_reg_caption: 'Регистрация',
    page_admin_caption: 'Панель управления',
    page_start_caption: 'Быстрый старт',
    page_error_caption: 'Что-то пошло не так',
    page_error_content: 'Пожалуйста, обратитесь в поддержку. Мы оперативно решим вашу проблему.',
    page_notFound_caption: 'Страница не найдена',
    page_notFound_content: 'Пожалуйста, воспользуйтесь меню для навигации по сайту',
    phrase_activate: 'Активировать',
    phrase_add: 'Добавить',
    phrase_alias: 'Псевдоним',
    phrase_align: 'Выравнивание',
    phrase_all: 'Все',
    phrase_anim_variant: 'Вариант анимации',
    phrase_apply_to_other: 'Применить к другим проектам',
    phrase_auto_start: 'Показать при старте',
    phrase_axes: 'Оси',
    phrase_axe_x: 'Ось X',
    phrase_axe_y: 'Ось Y',
    phrase_axe_z: 'Ось Z',
    phrase_back: 'Назад',
    phrase_cancel: 'Отмена',
    phrase_caption: 'Заголовок',
    phrase_caption_ru: 'Заголовок (ru)',
    phrase_caption_en: 'Заголовок (en)',
    phrase_categories: 'Категории',
    phrase_category: 'Категория',
    phrase_client_code: 'Код товара',
    phrase_clone: 'Создать копию',
    phrase_clone_complete: 'Создание копии завершено',
    phrase_close: 'Закрыть',
    phrase_colors: 'Цвета',
    phrase_color: 'Цвет',
    phrase_color_any: 'Возможность выбора из любого цвета',
    phrase_color_init: 'Цвет по умолчанию',
    phrase_color_schemes: 'Цветовые схемы',
    phrase_color_values: 'Список цветов',
    phrase_company: 'Компания',
    phrase_configurators: 'Проекты',
    phrase_copyright: '© 2021-2024. ООО «Сайт3Д»',
    phrase_copy: 'Копировать',
    phrase_copy_item: 'Копировать элемент',
    phrase_change_size: 'Изменить размер',
    phrase_create: 'Создать',
    phrase_creating: 'Создание',
    phrase_date: 'Дата',
    phrase_date_create: 'Дата создания',
    phrase_date_update: 'Дата обновления',
    phrase_date_start: 'Дата начала',
    phrase_date_end: 'Дата конца',
    phrase_default: 'По умолчанию',
    phrase_deselect_all: 'Снять выделение у всех',
    phrase_description: 'Описание',
    phrase_description_ru: 'Описание (ru)',
    phrase_description_en: 'Описание (en)',
    phrase_detail: 'Подробнее',
    phrase_domain: 'Домен',
    phrase_domains: 'Домены',
    phrase_drop_here: 'Отпустите файл здесь ...',
    phrase_drop_or_click: 'Перенесите файл <span class="hot">{formats}</span> сюда или кликните для выбора',
    phrase_edit: 'Редактировать',
    phrase_editing: 'Редактирование',
    phrase_email: 'Эл. почта',
    phrase_empty: 'Пусто',
    phrase_enter: 'Войти',
    phrase_fading: 'Затухание',
    phrase_file_load_from_file: 'Свой файл',
    phrase_file_load_from_variants: 'Из каталога',
    phrase_file_download: 'Скачать файл',
    phrase_file_downloading: 'Скачивание файла',
    phrase_file_loaded: 'Файл загружен',
    phrase_file_not_loaded: 'Файл не загружен',
    phrase_file_open: 'Открыть файл',
    phrase_file_variants: 'Каталог',
    phrase_file_remove_confirm: 'Действительно удалить файл?',
    phrase_filter: 'Фильтровать',
    phrase_font_size: 'Размер шрифта',
    phrase_further: 'Далее',
    phrase_group: 'Группа',
    phrase_group_toggle: 'Группа с одним активным переключателем',
    phrase_guide: 'Инструкция',
    phrase_height_map: 'Карта высот',
    phrase_roughness_map: 'Карта шероховатости',
    phrase_ambient_map: 'Карта затенения',
    phrase_hint: 'Подсказка',
    phrase_icon: 'Иконка',
    phrase_id: 'ID',
    phrase_image: 'Изображение',
    phrase_inside_label: 'Внутри метки',
    phrase_keep_proportions: 'Сохранить пропорции',
    phrase_load: 'Загрузить',
    phrase_loading: 'Загрузка',
    phrase_material: 'Материал',
    phrase_material_edited: 'Загрузка своей текстуры',
    phrase_material_create: 'Создание своей текстуры',
    phrase_material_copy: 'Копировать варианты материалов',
    phrase_material_copy_hint: 'Можете скопировать все варианты материала и вставить их в настройку материала для данной или другой части модели.',
    phrase_material_maps: 'Карты',
    phrase_material_is_common_maps: 'Использовать общие карты',
    phrase_material_tag: 'Теги материалов',
    phrase_max_length: 'Максимальная длина',
    phrase_menu: 'Меню',
    phrase_min_square: 'Минимальная площадь',
    phrase_model: 'Модель',
    phrase_model_part: 'Названия частей модели',
    phrase_model_part_hint: 'Названия заданы внутри 3D-модели. Чтобы применить настройку к нескольким частям модели, нужно указать общую для них часть имени или перечислить через запятую.',
    phrase_model_part_simple: 'Название части модели',
    phrase_model_part_simple_hint: 'Названия заданы внутри 3D-модели.',
    phrase_models_move: 'Режим перемещения',
    phrase_models_move_hint: 'В этом случае можно будет добавлять и перемещать модели по поверхности указанных частей проекта.',
    phrase_move: 'Переместить',
    phrase_move_up: 'Переместить вверх',
    phrase_move_down: 'Переместить вниз',
    phrase_name: 'Имя',
    phrase_new_project: 'Создать новый проект',
    phrase_no: 'Нет',
    phrase_not_found: 'Ничего не найдено',
    phrase_not_selected: 'Ничего не выбрано',
    phrase_ok: 'Хорошо',
    phrase_opacity: 'Прозрачность',
    phrase_paste: 'Вставить',
    phrase_paste_item: 'Вставить элемент',
    phrase_physical_properties: 'Физические свойства',
    phrase_physical_properties_metalness: 'Металличность',
    phrase_physical_properties_roughness: 'Шероховатость',
    phrase_physical_properties_emissive: 'Светимость',
    phrase_pos: 'Позиция',
    phrase_preload_caption: 'Заголовок до загрузки виджета',
    phrase_price: 'Стоимость',
    phrase_quality: 'Качество',
    phrase_range: 'Диапазон',
    phrase_refresh: 'Обновить',
    phrase_remove: 'Удалить',
    phrase_repeat: 'Повторение',
    phrase_repeat_texture: 'Повторить текстуру',
    phrase_repeat_type: 'Тип повторения',
    phrase_replace: 'Заменить',
    phrase_resend: 'Отправить повторно',
    phrase_reset_to_original: 'Сбросить к исходному значению',
    phrase_reset_to_original_confirm: 'Действительно вернуть первоначальное значение?',
    phrase_reverse: 'В обратную сторону',
    phrase_rotation: 'Вращение',
    phrase_rub: 'руб.',
    phrase_settings: 'Настройки',
    phrase_save: 'Сохранить',
    phrase_save_success: 'Данные успешно сохранены',
    phrase_scaling: 'Масштабирование',
    phrase_search: 'Поиск',
    phrase_seconds_short: 'сек.',
    phrase_select_all: 'Выбрать все',
    phrase_send: 'Отправить',
    phrase_set_geometry: 'Использовать только геометрию',
    phrase_set_geometry_hint: 'Когда в выбранном варианте загружена отдельная модель и включена данная опция, происходит замена геометрии части модели текущей настройки на геометрию первой части модели варианта.',
    phrase_show_button: 'Показать кнопку',
    phrase_size: 'Размер',
    phrase_size_length: 'Длина',
    phrase_size_height: 'Высота',
    phrase_size_width: 'Ширина',
    phrase_skybox: 'Панорама',
    phrase_sort: 'Сортировать',
    phrase_stat: 'Статистика',
    phrase_stat_activity: 'Активность',
    phrase_stat_rotations: 'Вращения',
    phrase_styles: 'Стили',
    phrase_styles_buttons: 'Кнопки',
    phrase_styles_panels: 'Панели',
    phrase_styles_window: 'Окна',
    phrase_styles_colors_primary: 'Основные цвета содержимого',
    phrase_styles_bg_colors_primary: 'Основные цвета фона',
    phrase_styles_colors_second: 'Дополнительные цвета содержимого',
    phrase_styles_bg_colors_second: 'Дополнительные цвета фона',
    phrase_styles_general: 'Обычный элемент',
    phrase_styles_active: 'Активный элемент',
    phrase_styles_selected: 'Выделенный элемент',
    phrase_styles_muted: 'Отключенный элемент',
    phrase_styles_disabled: 'Заблокированный элемент',
    phrase_styles_radius: 'Скругление углов',
    phrase_styles_saving: 'Сохранение стилей',
    phrase_support: 'Поддержка',
    phrase_support_description: '<p>Вначале, рекомендуем ознакомиться с <a href="/docs/guide_ru.pdf" target="_blank">руководством пользователя</a>.</p>' +
      '<p>Далее, напишите нам о своей задаче, и мы поможем в течение дня.</p>' +
      '<ul><li>Телеграм: <a href="https://t.me/site3d_support" target="_blank">@site3d_support</a></li><li>Почта: <a href="mailto:info@site3d.site">info@site3d.site</a></li></ul>' +
      '<p>При необходимости расширения текущих <a href="/buy">тарифов</a> действует индивидуальная система расчета стоимости доступа.</p>' +
      '<p>ПО распространяется в виде интернет-сервиса, поэтому специальные действия по установке, модернизации и устранению неисправностей на стороне пользователя не требуются.</p>' +
      '<p>Зарубежные аналоги, которых мы замещаем: Sketchfab, Vectary.</p>' +
      '<p><a href="/docs/technical-documentation_ru.pdf" target="_blank">Техническая документация</a></p>',
    phrase_tags: 'Теги',
    phrase_tariff: 'Тариф',
    phrase_text: 'Текст',
    phrase_text_ru: 'Текст (ru)',
    phrase_text_en: 'Текст (en)',
    phrase_texture: 'Текстура',
    phrase_thickness: 'Толщина',
    phrase_toggle_on: 'Развернуть',
    phrase_toggle_off: 'Свернуть',
    phrase_to_first_page: 'К первой странице',
    phrase_to_last_page: 'К последней странице',
    phrase_turn_on: 'Включить',
    phrase_turn_off: 'Выключить',
    phrase_type: 'Тип',
    phrase_value: 'Значение',
    phrase_value_init: 'Начальное значение',
    phrase_value_min: 'Минимальное значение',
    phrase_value_max: 'Максимальное значение',
    phrase_value_step: 'Шаг изменения',
    phrase_view: 'Вид',
    phrase_view_tile: 'Плитка',
    phrase_view_list: 'Список',
    phrase_unit: 'Единицы измерения',
    phrase_url: 'URL',
    phrase_yes: 'Да',
    phrase_yes_no_errors: 'Без ошибок',
    phrase_yes_errors: 'С ошибками',
    planner_ground: 'Площадка',
    posts_not_found: 'Нет постов',
    posts_loading: 'Загружается раздел постов',
    posts_content_not_found: 'Пост не найден',
    posts_content_loading: 'Загружается пост',
    posts_content_button_try: 'Попробуйте бесплатно добавить 3D на сайт',
    posts_content_all: 'Все посты',
    price_info: 'Стоимость',
    price_info_is_visible: 'Показать',
    price_info_init_value: 'Начальное значение',
    price_info_currency: 'Валюта',
    product_properties: 'Свойства продукта',
    product_code_caption: 'Код',
    product_code_hint: 'Может использоваться для вставки виджета на сайт вместо ID проекта',
    profile_caption: 'Редактирование профиля',
    reg_caption: 'Немного о себе перед началом...',
    reg_send: 'К созданию 3D',
    reg_welcome: '<p>Добро пожаловать, {name}, в наш сервис.</p>' +
      '<ul>' +
      '<li>Подпишитесь на наш <a href="https://t.me/site3d_configurator" target="_blank">телеграм-канал</a></li>' +
      '<li>Пришлите нам <a href="https://t.me/site3d_support" target="_blank">в поддержку</a> слово <span class="hot">ПОДАРОК</span></li>' +
      '<li>Получите <span class="hot">10 дней на максимальном тарифе</span></li>' +
      '</ul>',
    repeats_once: 'Раз',
    repeats_loop: 'Цикл',
    repeats_forward_and_back: 'Туда и обратно',
    rotate_correction_none: 'Нет',
    rotate_correction_x_90: '90° по X',
    rotate_correction_x_180: '180° по X',
    rotate_correction_x_270: '270° по X',
    rotate_correction_z_90: '90° по Z',
    rotate_correction_z_180: '180° по Z',
    rotate_correction_z_270: '270° по Z',
    rotate_directions_clockwise: 'По часовой',
    rotate_directions_counterclockwise: 'Против часовой',
    scene_camera: 'Первоначальное положение камеры',
    scene_auto_center: 'Поместить модель по центру',
    scene_bound_center: 'Исправить центры деталей',
    scene_bound_center_hint: 'Работает не для всех случаев (смотрите <a href="/docs/guide_ru.pdf" target="_blank">наше руководство</a> в разделе подготовки модели)',
    scene_start_rotate: 'Поворот камеры при старте',
    scene_start_help: 'Анимационные иконки при старте',
    scene_auto_load: 'Автоматическая загрузка',
    scene_start: 'Стартовый вид',
    scene_nav_buttons: 'Кнопки навигации',
    scene_nav_main_buttons: 'Основные',
    scene_nav_additional_buttons: 'Дополнительные',
    scene_nav_button_pos: 'Первоначальный вид',
    scene_nav_button_full: 'Полноэкранный режим',
    scene_nav_button_rotate: 'Автоповорот',
    scene_nav_button_light: 'Свечение',
    scene_nav_button_anim: 'Анимация',
    scene_nav_button_skybox: 'Панорамы',
    scene_nav_button_size: 'Размеры',
    scene_nav_button_label: 'Метки',
    scene_nav_button_vr: 'Виртуальная реальность',
    scene_nav_button_ar: 'Дополненная реальность',
    scene_nav_button_share: 'Поделиться PDF',
    scene_nav_button_link: 'Поделиться ссылкой',
    scene_nav_button_download: 'Скачать модель',
    scene_nav_button_screenshot: 'Сделать скриншот',
    scene_nav_link: 'Поделиться ссылкой',
    scene_nav_help: 'Окно помощи',
    scene_nav_help_text: 'Текст сообщения',
    scene_nav_help_auto_start: 'Показать при старте',
    scene_nav_start_show_buttons: 'Показать кнопки при старте',
    scene_nav_start_show_hot_ar: 'Большая кнопка AR',
    scene_nav_start_show_panels: 'Показать панели при старте',
    scene_pos_from_model_hint: 'Дистанция до модели в\u00a0% и\u00a0повороты',
    scene_use_target: 'Вращать вокруг модели',
    scene_scale: 'Масштабирование',
    scene_scale_control_hint: 'Диапазон приближения и отдаления камеры в процентах от размеров модели',
    scene_pos_correction: 'Коррекция смещения',
    scene_size_correction: 'Коррекция размера',
    scene_rotate: 'Вращение',
    scene_rotate_control_full: 'Без ограничений',
    scene_rotate_correction: 'Коррекция поворота',
    scene_auto_rotate: 'Автоповорот',
    scene_auto_rotate_auto_start: 'Вращать при старте',
    scene_auto_rotate_direction: 'Направление',
    scene_auto_rotate_speed: 'Скорость',
    scene_anim_control: 'Анимация',
    scene_anim_control_auto_start: 'Показать при старте',
    scene_anim_control_active_by_click: 'Активировать по клику',
    scene_anim_control_number: 'Номер',
    scene_anim_control_number_not_found: 'Анимация не найдена',
    scene_anim_control_common_settings: 'Общие настройки анимации',
    scene_anim_control_repeat: 'Повторять',
    scene_anim_control_speed: 'Скорость',
    scene_anim_control_variants: 'Варианты',
    scene_anim_control_variants_empty: 'Варианты анимаций еще не добавлены',
    scene_anim_control_variants_remove_confirm: 'Действительно хотите удалить вариант анимации?',
    scene_anim_control_manual: 'Ручное управление',
    scene_anim_control_marks: 'Метки',
    scene_anim_control_marks_hint: 'Вы можете показывать всплывающий текст для определенного момента анимации',
    scene_anim_control_marks_empty: 'У вас еще нет меток',
    scene_anim_control_marks_remove_confirm: 'Действительно хотите удалить метку?',
    scene_anim_control_hover: 'При наведении',
    scene_anim_control_show_panel: 'Показать панель',
    scene_size_control: 'Размеры',
    scene_size_control_auto_start: 'Показать при старте',
    scene_size_control_unit: 'Единицы измерения',
    scene_size_control_recalc: 'Пересчитать',
    scene_floor: 'Пол',
    scene_floor_view_rectangle: 'Прямоугольник',
    scene_floor_view_circle: 'Круг',
    scene_floor_scale: 'Масштаб',
    scene_floor_color: 'Цвет',
    scene_skybox: 'Панорамы',
    scene_skybox_hint: 'Изображения 360 градусов в\u00a0формате jpeg или png. Можно скачать <a href="https://polyhaven.com/hdris" target="_blank">здесь</a>.',
    scene_skybox_image: 'Изображение',
    scene_skybox_image_hint: 'Изображение 360 градусов в\u00a0формате jpeg или png',
    scene_skybox_empty: 'Панорамы еще не добавлены',
    scene_skybox_remove_confirm: 'Действительно хотите удалить панораму?',
    scene_skybox_show_default: 'Панорама по умолчанию',
    scene_light: 'Освещение',
    scene_ambient_light: 'Общий свет',
    scene_ambient_light_hint: 'Мощность и\u00a0цвет',
    scene_directional_light: 'Направленный свет сверху',
    scene_directional_light_up: 'Направленный свет снизу',
    scene_directional_light_hint: 'Мощность, наклоны и\u00a0цвет',
    scene_ar: 'Дополненная реальность',
    scene_graphics: 'Графика',
    setting_size: 'Общие размеры',
    skybox_field: 'Поле',
    skybox_forrest: 'Лес',
    start_model_loading: 'Загрузка модели',
    start_model_load_failed: 'Не удалось загрузить модель',
    styles_padding: 'Отступ от краев',
    styles_fontSize: 'Размер шрифта',
    styles_fontWeight: 'Толщина шрифта',
    styles_fontWeight_normal: 'Обычный',
    styles_fontWeight_bold: 'Жирный',
    styles_color: 'Цвет текста',
    styles_backgroundColor: 'Цвет фона',
    styles_borderWidth: 'Толщина обводки',
    styles_borderColor: 'Цвет обводки',
    styles_borderRadius: 'Радиус обводки',
    tags_no: 'Без тегов',
    tags_empty: 'Тегов нет',
    tags_remove_confirm: 'Действительно хотите удалить данный тег?',
    tariff: 'Тариф',
    tariff_test: 'Протестируйте бесплатно',
    tariff_hit: 'Хит',
    tariff_free: 'Бесплатно навсегда',
    tariff_month: 'месяц',
    tariff_year: 'год',
    tariff_buy_caption: '{price} ₽ в {period}',
    tariff_buy_description: 'Оплата подписки Site3D Configurator на {period} по тарифу {caption} от {userInfo}',
    tariff_economy: 'Экономите {price} ₽',
    tariff_configurators: 'До {count} проектов',
    tariff_configurators_no: 'Безлимитное число проектов',
    tariff_time_active: 'Время работы: {time}\u00a0секунд',
    tariff_time_active_no: 'Безлимитное время работы',
    tariff_watermark_yes: 'Водяной знак',
    tariff_watermark_no: 'Без водяного знака',
    tariff_ar_yes: 'Примерка в реальном пространстве',
    tariff_ar_no: 'Без примерки в реальном пространстве',
    tariff_limit_configurators: 'На вашем тарифе максимально доступно {count} проектов',
    tariff_no_edit_scene: 'Настройка сцены сразу для всех проектов доступна на любом оплаченном тарифе, начиная с тарифа <a href="/buy">Базовый</a>. Если вам нужно протестировать данную возможность, напишите, пожалуйста, <a href="/support">в поддержку</a>.',
    tariff_no_edit_configurators: 'Массовое редактирование проектов доступно только на оплаченном тарифе <a href="/buy">Премиум</a>. Если вам нужно протестировать данную возможность, напишите, пожалуйста, <a href="/support">в поддержку</a>.',
    tariff_limit_auto_created_configurators: 'Превышено максимальное число автоматически создаваемых проектов. Для решения вопроса просьба написать <a href="/support">в поддержку</a>.',
    tariff_no_branding: 'Брендирование доступно только на оплаченном тарифе <a href="/buy">Премиум</a>. Если вам нужно протестировать данную возможность, напишите, пожалуйста, <a href="/support">в поддержку</a>.',
    tariff_speed: 'Ускорение просмотра',
    tariff_1: 'Бесплатный',
    tariff_2: 'Старт',
    tariff_3: 'Базовый',
    tariff_4: 'Конфигуратор',
    tariff_5: 'Премиум',
    tariff_paid: 'Платный',
    tariff_additional_options: '<h3 class="contacts__blockCaption">Возможности кастомизации тарифов</h3>' +
      '<div class="contacts__blockContent contacts__blockContent_small">' +
      '<p>Увеличить максимальный размер моделей на 50&nbsp;Мб &mdash; 3&nbsp;000&nbsp;₽ в год</p>' +
      '<p>Дополнительный домен &mdash; 6&nbsp;000&nbsp;₽ в год</p>' +
      '<p>Возможность автоматически добавить дополнительно 1000 проектов &mdash; 10&nbsp;000\u00a0₽ в год</p>' +
      '</div>',
    tariff_payment: '<h3 class="contacts__blockCaption">Реквизиты для перевода в рублях юридическим лицам</h3>' +
      '<div class="contacts__blockContent contacts__blockContent_small">' +
      '<p>ИП Ткаченко Алексей Сергеевич</p>' +
      '<p>ИНН: 272549116833</p>' +
      '<p>Банк: АО «Т-Банк»</p>' +
      '<p>БИК: 044525974</p>' +
      '<p>Корр. счет: 30101810145250000974</p>' +
      '<p>Расчетный счет: 40802810100000511007</p>' +
      '</div>',
    tariff_start_offer: 'Бесплатно 10 дней на тарифе «Премиум»',
    tariff_start_conditions: 'Нужно лишь подписаться на наш <a href="https://t.me/site3d_configurator" target="_blank">телеграм-канал</a>',
    tariff_start_after: 'После завершения пробного периода все ваши проекты сохранятся, а вы будете переведены на бесплатный тариф, где будут действовать следующие ограничения: не более 5\u00a0проектов, до\u00a01 минуты просмотра, логотип сервиса.',
    technical_works: '<p>Скоро все заработает!</p>' +
      '<p>Мы улучшаем сервис для Вас. Приносим извинения за неудобства.</p>' +
      '<p>Вы также можете написать нам <a href="/support">в поддержку</a>.</p>',
    total: 'Всего',
    type: 'Тип конфигуратора',
    type_1: 'Модель',
    type_2: 'Планировщик',
    units_mm: 'мм',
    units_cm: 'см',
    units_m: 'м',
    user_anonymous: 'Анонимный пользователь',
    user_no_access: 'У вас нет достаточных прав',
    user_date_reg: 'Дата регистрации',
    user_date_update: 'Дата обновления',
    user_date_active: 'Дата окончания действия подписки',
    user_tariff: 'Тариф',
    user_additional_size: 'Дополнительный размер',
    user_additional_domains: 'Дополнительные домены',
    user_additional_auto_created: 'Дополнительные автоматически создаваемые проекты',
    user_is_paid: 'Оплаченный аккаунт',
    user_name: 'Имя',
    user_email: 'Эл. почта',
    user_phone: 'Телефон',
    user_type: 'Тип аккаунта',
    user_type_empty: 'Не выбран',
    user_type_company: 'Бизнес',
    user_type_freelancer: 'Персональный',
    user_company: 'Компания',
    user_position: 'Должность',
    user_password: 'Пароль',
    user_purpose: 'Цель использования',
    user_purpose_0: 'Не выбрана',
    user_purpose_1: 'Коммерческая',
    user_purpose_2: 'Образовательная',
    user_published_count: 'Число опубликованных проектов',
    user_is_partner: 'Партнер',
    user_is_activated: 'Активированный',
    user_activation_code: 'Код активации',
    user_activation_code_hint: 'Если вы не получили код активации на свой e-mail, напишите нам в <a href="/support">поддержку</a>.',
    user_activation_code_description: 'Мы направили на ваш e-mail письмо с кодом активации для подтверждения регистрации.',
    user_activation_check_error: 'Ошибка при вводе кода активации',
    user_activation_resend_success: 'Повторно отправили код активации на ваш e-mail',
    user_ref_link: 'Реферальная ссылка',
    user_status_test_end: 'Заканчивается тестовый период',
    user_status_gone: 'Пропали после регистрации',
    user_status_not_active: 'Давно не заходили',
    user_status_without_prolongation: 'Не продлили подписку',
    user_status_active: 'Активные подписчики',
    wrong_number_domains: '<p>Превышено максимальное число доменов: {domainsMaxCount}.</p>' +
      '<p>Поддомены бесплатны в любом количестве, начиная с тарифа Базовый.</p>' +
      '<p>По приобретению дополнительных доменов просьба написать в <a href="/support">поддержку</a></p>'
  },
  [LOCALES.ENGLISH]: {
    action: '<p>Subscribe to our <a href="https://t.me/site3d_configurator" target="_blank">telegram channel</a>, write to the <a href="https://t.me/site3d_support" target="_blank">administrator</a> about this and get an <span class="hot">extra 10&nbsp;days</span> on the maximum tariff.</p>',
    additional_tools: 'Additional tools',
    additional_tools_move: 'Move to user',
    additional_tools_move_error: 'Failed to move the project to the user',
    additional_tools_move_success: 'The project has been successfully moved',
    admin_menu_users: 'Users',
    admin_menu_catalog_models: 'Catalog of models',
    admin_menu_news: 'News',
    admin_menu_posts: 'Posts',
    admin_menu_faq: 'FAQ',
    admin_menu_system: 'System',
    admin_users_empty: 'Nothing users',
    admin_users_remove_confirm: 'Do you really want to remove this user?',
    admin_users_configurators: '{configurators}',
    admin_news_remove_confirm: 'Do you really want to remove this news?',
    admin_posts_remove_confirm: 'Do you really want to remove this post?',
    admin_faq_remove_confirm: 'Do you really want to remove this  question?',
    admin_system_clear_data: 'Clear unused user data',
    admin_system_update_configurator_hash: 'Update hashes for project references',
    admin_system_update_domains: 'Update domains',
    admin_system_update_settings: 'Update settings',
    admin_system_technical_works: 'Technical works',
    align_center: 'Center',
    align_top: 'Top',
    align_right_top: 'Right top',
    align_right: 'Right',
    align_right_bottom: 'Right bottom',
    align_bottom: 'Bottom',
    align_left_bottom: 'Left bottom',
    align_left: 'Left',
    align_left_top: 'Left Top',
    anim_numbers_none: 'None',
    anim_numbers_all: 'All',
    ar_models: 'Models for AR',
    ar_models_glb: 'For  Android in GLB format',
    ar_models_glb_hint: 'GLB files are created automatically when augmented reality mode is activated, but you can download your own version of this format.',
    ar_models_usdz: 'For iOS in USDZ format',
    ar_models_usdz_hint: 'USDZ files are created automatically when augmented reality mode is activated, but you can download your own version of this format.',
    ar_url_hint: 'Link to a third-party AR viewer',
    ar_markers: 'Markers',
    ar_markers_empty: 'Markers have not been added yet',
    ar_marker_remove_confirm: 'Do you really want to remove this marker?',
    auth_caption: 'Authorization',
    auth_days: 'Active subscription days: {days}',
    auth_days_no: 'You need to pay for a subscription',
    auth_login: 'Login',
    auth_logout: 'Logout',
    auth_reg: 'Registration',
    auth_forgot_password: 'Forgot your password?',
    auth_forgot_password_confirm: 'Do you really want to reset your old password?',
    auth_forgot_password_subject: 'Password recovery',
    auth_forgot_password_body: 'Your new password to access Site3D Configurator: [password]',
    backend_auth_failed: 'Wrong email or password',
    backend_reg_success: 'Your registration was success',
    backend_user_exist: 'User with the same email is already exist',
    backend_user_not_exist: 'User with such email is not registered',
    backend_forgot_password_success: 'A letter with a new password has been sent to your email',
    backend_profile_edit_success: 'Profile has been successfully edited',
    backend_configurator_not_found: 'The requested project was not found',
    banner_cookieAgree: 'Cookies are used on this website.',
    block_present_caption_main: 'Embed 3D-models on the website and increase the conversion rate by 7%',
    block_present_caption_houses: 'Embed 3D-models of houses on the website and increase the flow of applications for 39%',
    block_present_caption_pavilions: 'Embed 3D-models of pavilions on the website and increase the flow of applications for 39%',
    block_present_caption_stairs: 'Embed 3D-models of stairs on the website and increase the flow of applications for 39%',
    block_present_caption_playgrounds: 'Embed 3D-models of playgrounds on the website and increase the conversion rate by 7%',
    block_present_caption_fireplaces: 'Embed 3D-models of fireplaces on the website and increase the conversion rate by 7%',
    block_present_caption_industry: 'Embed 3D-models of industrial equipment on the website and increase the conversion rate by 7%',
    block_present_slogan: '<span class="textEffect"><span class="textEffect__item textEffect__item_1">Available&nbsp;</span><span class="textEffect__item textEffect__item_2"><span class="textEffect__itemContent textEffect__itemContent_2">to&nbsp;any&nbsp;business from&nbsp;550&nbsp;₽</span></span></span>',
    block_present_description: '<ul>' +
      '<li>All is&nbsp;in&nbsp;the&nbsp;browser and&nbsp;without&nbsp;programming</li>' +
      '<li>Free for 10 days and a demo project</li>' +
      '<li><span class="hot">No limits on the number of 3D-models</span></li>' +
      '<li>There are only real people in the support</li>' +
      '<li>API for&nbsp;closer&nbsp;integration</li>' +
      '<li><span class="russia">We work from&nbsp;Russia</span></li>' +
      '</ul>',
    block_present_gift: 'Get the gift',
    block_present_gift_text: '<h2>Additional 10&nbsp;days on&nbsp;the&nbsp;maximum tariff</h2>' +
      '<ul>' +
      '<li><a href="/reg" target="_blank">Register</a>, if you haven\'t done so yet</li>' +
      '<li>Send us <a href="https://t.me/site3d_support" target="_blank">in support</a> the word <span class="hot">GIFT</span> and the e-mail address specified during registration</li>' +
      '</ul>',
    block_present_try: 'Increase sales',
    block_present_widget_description_main: 'Example of a 3D-configurator for a website',
    block_present_widget_description_houses: 'Example of a 3D-configurator of the house for a website',
    block_present_widget_description_pavilions: 'Example of a 3D-configurator of the pavilion for a website',
    block_present_widget_description_stairs: 'Example of a 3D-configurator of the stairs for a website',
    block_present_widget_description_playgrounds: 'Example of a 3D-configurator of the playground for a website',
    block_present_widget_description_fireplaces: 'Example of a 3D-configurator of the fireplace for a website',
    block_present_widget_description_industry: 'Example of a 3D-configurator of the container gas station for a website',
    block_present_widget_link_main_1: 'Example of a link to view a 3D-model',
    block_present_widget_link_houses_1: 'Example of a link to view a 3D-model of the country house',
    block_present_widget_link_pavilions_1: 'Example of a link to view a 3D-designer of the winter garden',
    block_present_widget_link_stairs_1: 'Example of a stairs constructor with fences',
    block_present_widget_link_stairs_2: 'Example of a link to view a 3D-model of a staircase with interactive animation',
    block_present_widget_link_playgrounds_1: 'Example of a link to view a 3D-model of the playground',
    block_present_widget_link_fireplaces_1: 'Example of a link to view a 3D-model of the fireplace',
    block_present_widget_link_industry_1: 'Example of a link to view a 3D-model of the nitrogen node',
    block_problem_caption: 'Solving the problem of displaying the product on the website',
    block_problem_description: 'A 2D-image can lead to loss of customers when it cannot accurately convey the nuances of the shapes and properties of the product',
    block_problem_product: '2D-image of a furniture handle',
    block_problem_client: 'The buyer\'s closet',
    block_problem_solution: 'Interactive 3D is clearer',
    block_show_name: 'Interactive 3D viewing',
    block_show_description: 'A cloud-based service for the implementation of interactive 3D-models in the form of blocks on the site or links to view with the ability to configure.',
    block_gift_caption: 'Find out how 3D-technologies affect sales',
    block_gift_description: 'See how others use modern marketing tools',
    block_steps_caption: 'How does it work?',
    block_steps_description: 'We will give clear instructions at all stages. We are in touch daily.',
    block_steps_1_caption: 'Upload your own 3D-model to our service',
    block_steps_1_description: '<p>Where you can get 3D-models if your employees don\'t have them:</p>' +
      '<ul>' +
      '<li>Request by our <a href="/docs/guide_en.pdf " target="_blank">instructions</a> from the manufacturers or from those who provided you with static renderers</li>' +
      '<li>Order from our partners, <a href="/support">by writing to support</a></li>' +
      '</ul>' +
      '<p>We also wrote <a href="/docs/guide_en.pdf " target="_blank">guide</a> for preparing models and working inside the service.</p>',
    block_steps_2_caption: 'Setting up viewing',
    block_steps_2_description: 'Any employee can handle it, and if you have any questions, our <a href="/support">support service</a> is always in touch',
    block_steps_3_caption: 'Get a ready-made code to the website or a link to view',
    block_steps_3_description: 'Our code can be embedded into any website in 5 minutes',
    block_steps_4_caption: 'Embed the code on the website or share the link',
    block_steps_4_description: 'And in your personal account you can make <span class="hot">photo and video materials</span> from 3D-models.',
    block_steps_video_name: 'How the Site3D Configurator service works',
    block_steps_video_description: 'We tell you step by step about the introduction of blocks on the site with 3D viewing.',
    block_steps_try: 'Test it for free',
    block_ideas_caption: 'Attract more buyers',
    block_ideas_description: 'Get inspired by our ideas for using 3D-models or come up with your own',
    block_ideas_image_description: 'Creating a 3D-configurator in the Site3D Configurator service with the display of the result in augmented reality',
    block_ideas_structure_caption: 'An inside look',
    block_ideas_structure_description: 'The explosion diagram will help you quickly and clearly show what a product or service consists of',
    block_ideas_action_caption: 'The product is in action',
    block_ideas_action_description: 'Animation instead of a static photo will tell more about the product and attract the attention of the buyer',
    block_ideas_manual_caption: 'Interactive animation',
    block_ideas_manual_description: 'Hold the user\'s attention with spectacular animation of 3D-models controlled by the mouse wheel or swipe across the screen',
    block_ideas_configurator_caption: 'Own version of the product',
    block_ideas_configurator_description: 'Give customers the opportunity to customize the product to their needs and requests',
    block_ideas_tour_caption: '3D tour through models',
    block_ideas_tour_description: 'Our 3D-tours are made not from photos, but from 3D-models, which allows you to set any viewing points and easily make any changes in the scene',
    block_ideas_text_caption: 'Own labels on the model',
    block_ideas_text_description: 'Your clients can apply arbitrary text to various parts of the 3D-model and send the result to you by link',
    block_ideas_move_caption: 'Moving models',
    block_ideas_move_description: 'Allow customers to arrange several models in one space',
    block_benefits_caption: 'Get results',
    block_benefits_description: 'Increase the efficiency of your business',
    block_benefits_1_caption: 'Stand out\nfrom the competition',
    block_benefits_1_description: '3D-technologies will become your strong competitive advantage',
    block_benefits_2_caption: 'Raise\nin the search engines',
    block_benefits_2_description: '3D-models increase the time spent on the website, which has a positive effect on search engine promotion',
    block_benefits_3_caption: 'Simplify\ncustomer interaction',
    block_benefits_3_description: 'Give the customer the opportunity to customize the product himself and evaluate the options instead of communicating with managers',
    block_benefits_4_caption: 'Increase\nsales on the website',
    block_benefits_4_description: 'According to a study by Svyaznoy, 3D-models give an average conversion growth of 7%',
    block_capabilities_caption: 'Everything will work out with us',
    block_capabilities_description: 'Get an effective solution to your problem',
    block_capabilities_optimize_caption: 'Effective algorithms for accelerating the viewing of 3D-models',
    block_capabilities_optimize_name: 'Speed up the viewing of 3D-models with a large number of elements',
    block_capabilities_optimize_description: 'Unique algorithms increase the response speed when viewing heavy 3D-models',
    block_capabilities_formats_caption: 'Support for popular 3D-model formats',
    block_capabilities_formats_description: 'Our service allows you to upload 3D-models of formats such as GLB, FBX, STL, OBJ, 3DS and others',
    block_capabilities_textures_caption: 'Limitless options\ncolors and\u00a0textures',
    block_capabilities_textures_description: 'The ability to visually demonstrate the various colors of the product (at the same time, the user can try on his own image on the model)',
    block_capabilities_environment_caption: 'Products are entered\nin the environment',
    block_capabilities_environment_description: 'Show your product with a realistic panorama, where 3D-models are located on the surface, and not "hanging in the air"',
    block_capabilities_ar_caption: '3D fitting\nin\u00a0reality',
    block_capabilities_ar_description: 'Augmented reality allows you to place goods in the real world (for example, try on furniture in your interior)',
    block_capabilities_animation_caption: 'Animation\nwith explanatory text',
    block_capabilities_animation_description: 'Helps to quickly bring key information about the product',
    block_capabilities_video_caption: 'Video reviews\nof the necessary options',
    block_capabilities_video_description: 'You can create videos about your products with different colors and accessories in one click',
    block_capabilities_labels_caption: 'Convenient viewing\nindividual elements',
    block_capabilities_labels_description: 'Interactive tags help to focus on the advantages and features of your product',
    block_capabilities_parts_caption: 'Replacement and opening\nproduct parts',
    block_capabilities_parts_description: 'The opportunity to see different variants of your offer',
    block_capabilities_lod_caption: 'Different levels of detail',
    block_capabilities_lod_description: 'While the camera is approaching the object, you can load more product details',
    block_capabilities_stat_caption: 'Product interaction statistics',
    block_capabilities_stat_description: 'Now you will be able to understand which products your customers are more interested in.',
    block_capabilities_order_caption: 'Cost calculation\nand ordering',
    block_capabilities_order_description: 'Customers can see the price of the product depending on the selected options and send an order directly from the 3D-widget',
    block_capabilities_themes_caption: 'Individual\ndesign themes',
    block_capabilities_themes_description: 'Support for the unique appearance of a 3D-block for your brand',
    block_capabilities_promo_name: 'Promo video about the service of introducing 3D-models to websites Site3D Configurator',
    block_capabilities_promo_description: 'Site3D Configurator is an online service for the rapid implementation of 3D-configurators on websites with support for such useful business opportunities as: loading 3D-models of popular formats, animation management, replacement of textures and components.',
    block_capabilities_ar_video_name: 'Augmented reality in the service of introducing 3D-models to sites Site3D Configurator',
    block_capabilities_ar_video_description: 'The 3D-widget of the Site3D Configurator service allows you to try on models to real interiors, which allows, for example, to make a more meaningful choice of some thing before buying it.',
    block_promo_name: 'Russian platform for creating 3D presentations of goods and services',
    block_promo_description: 'Demonstration of the creation of a 3D-widget to be inserted into the site in order to increase the conversion of online sales.',
    block_api_caption: 'Documented API for advanced integration',
    block_api_description: 'When you need to link your site\'s scripts to our widget',
    block_api_content: '<p><a href="/docs/api_en.pdf" target="_blank">Documentation on the Site3D Configurator software interface</a></p>' +
      '<p>Test the API using the example of this 3D-widget</p>',
    block_api_test_color: 'Change the color',
    block_api_test_own_texture: 'Upload your texture',
    block_api_test_share: 'Share a user\'s choice',
    block_api_test_pdf_button: 'Create PDF document',
    block_api_test_pdf_start: 'Creating PDF document',
    block_api_test_screenshot_button: 'Create screenshot',
    block_api_test_screenshot_start: 'Creating screenshot',
    block_api_test_ar: 'Augmented reality',
    block_api_test_ar_button: 'Try it on in AR',
    block_api_test_view: 'Controlling the display of the scene',
    block_api_test_view_load: 'Load the scene',
    block_api_test_view_render: 'Enable scene drawing',
    block_api_test_no_widget: 'The 3D-widget is not loaded',
    block_api_test_no_image: 'An image is not loaded',
    block_calmness_caption: 'It will be quiet with us',
    block_calmness_description: 'We are aimed at long-term cooperation',
    block_calmness_support_caption: 'We are constantly in touch',
    block_calmness_support_description: 'Our support service is open daily from 10:00 to 21:00. We will help you with any questions.',
    block_calmness_free_caption: 'Your projects are available even after the subscription ends',
    block_calmness_free_description: 'In this case, you will be transferred to&nbsp;the&nbsp;<a href="/buy">free&nbsp;tariff</a>',
    block_calmness_availability_caption: 'Our 3D-widgets are adapted to any device',
    block_calmness_availability_description: 'Our solution is available for desktop and mobile devices',
    block_calmness_safe_caption: 'Your 3D-models are protected from\u00a0downloading',
    block_calmness_safe_description: 'Users can only view models. You can also place any watermark on top of the model.',
    block_cases_caption: 'Our cases',
    block_cases_description: 'We benefit the business. Expanding boundaries of possibilities',
    block_cases_task: 'What was the task?',
    block_cases_before: 'Before',
    block_cases_after: 'After',
    block_cases_result: 'Result',
    block_cases_link: 'See the example',
    block_cases_link_1: 'See the example\u00a01',
    block_cases_link_2: 'See the example\u00a02',
    block_cases_lesenka: 'Interactive 3D-models of stairs with a fitting in reality',
    block_cases_lesenka_task: 'To increase the number of applications for the manufacture of stairs from the site by 10-15% by placing 3D-widgets for the most popular product items',
    block_cases_lesenka_before: 'Photos of stairs',
    block_cases_lesenka_after: '3D product overview from all possible angles and with augmented reality',
    block_cases_lesenka_result: '<ul>' +
      '<li>The average time spent by users on the site has increased by 42%</li>\n' +
      '<li>The visibility of the online store in search engines has increased by 22%</li>\n' +
      '<li>The number of applications from the site increased by 39% compared to May last year</li>\n' +
      '</ul>',
    block_cases_playgrounds: '3D-viewing of playground models',
    block_cases_playgrounds_task: 'To enable site visitors to view products from any angle and try them on their site using augmented reality',
    block_cases_playgrounds_before: 'Static renderings and photos of finished objects',
    block_cases_playgrounds_after: 'Interactive 3D-widget in product cards',
    block_cases_playgrounds_result: 'The number of applications for the construction of children\'s play and sports complexes from the site increased by 9%',
    block_cases_architent: '3D-configurator of tents',
    block_cases_architent_task: 'To enable site visitors to design their own version of the tent',
    block_cases_architent_before: 'Static rendering',
    block_cases_architent_after: 'Interactive 3D-configurator',
    block_cases_architent_result: 'The number of applications from the site has been increased by 8%',
    block_cases_aluminante: '3D-configurator of the pavilion',
    block_cases_aluminante_task: 'Show possible configurations and colors of pavilion, as well as animation of glazing',
    block_cases_aluminante_before: 'Photos of individual solutions',
    block_cases_aluminante_after: '3D-preview with the ability to view animations, change materials and equipment',
    block_cases_aluminante_result: 'The involvement of visitors in the purchase has increased by 2 times.',
    block_cases_cabin: '3D-configurator of the cabin with animation',
    block_cases_cabin_task: 'Increase orders for rental cabin',
    block_cases_cabin_before: 'The service page contained only text and static images',
    block_cases_cabin_after: 'We have introduced an interactive 3D-widget of a cabin with animation of the components of the product into the description of the service',
    block_cases_cabin_result: 'The number of customers for the service has increased 1.8 times.',
    block_cases_cottage: '3D-preview of house',
    block_cases_cottage_task: 'It is most aesthetically pleasing to show 3D-models of the house',
    block_cases_cottage_before: 'Standard viewers: static renderers and video reviews',
    block_cases_cottage_after: 'A spectacular 3D widget of the house has been added with the ability to rotate, scale, view the layout from the inside and configure',
    block_cases_cottage_result: 'The buyer can consider options for cottages from any angle and with the necessary approximation.',
    block_cases_house: '3D-configurator of a housing complex with layouts',
    block_cases_house_task: 'Increase user engagement in buying an apartment in a residential complex',
    block_cases_house_before_1: 'Only two angles of the appearance of the project',
    block_cases_house_before_2: 'It is difficult to determine the windows of a particular apartment',
    block_cases_house_before_3: 'It is not easy to understand the view from the windows',
    block_cases_house_after_1: 'The rotation and scaling of the building allows to appreciate the architectural delights much more fully than a static picture',
    block_cases_house_after_2: 'Thanks to the ability to easy change the angle of the view of the house, you can easily identify the windows of any apartment',
    block_cases_house_after_3: 'The interactive map of the environment makes it possible to see the view from the windows',
    block_cases_house_result: 'The average time users spent on the project page has increased 2.4 times.',
    block_cases_furniture: '3D-review of the furniture',
    block_cases_furniture_task: 'To give customers the opportunity to try on furniture in their interior without leaving home',
    block_cases_furniture_before: 'Static image of the product',
    block_cases_furniture_after: 'Interactive 3D model of the product with the possibility of fitting in a real environment',
    block_cases_furniture_result: 'The number of applications from the site increased by 8%',
    block_cases_sloros: 'Uploading a large number of 3D-models to the viewing site',
    block_cases_sloros_task: 'Implement 3D-widgets in the marketplace for viewing more than 1000\u00a0products in three-dimensional space',
    block_cases_sloros_before: 'Standard Images',
    block_cases_sloros_after: 'Interactive 3D-preview',
    block_cases_sloros_result: 'The client\'s models were successfully implemented on the site within a week.',
    block_cases_kaflon: '3D-overview of electromagnetic flowmeters',
    block_cases_kaflon_task: 'Demonstration of products in 3D',
    block_cases_kaflon_before: 'Product photos only',
    block_cases_kaflon_after: '3D-preview with the ability to share a link',
    block_cases_kaflon_result: 'After a month of implementing the 3D-widget, sales increased by 16%.',
    block_cases_madkit: '3D-constructor of a fire extinguisher',
    block_cases_madkit_task: 'Create an interactive 3D fire extinguisher widget with the ability to change the color of the case and overlay a custom image on it',
    block_cases_madkit_before: 'Only photos and videos',
    block_cases_madkit_after: 'An interactive 3D fire extinguisher configurator has been added',
    block_cases_madkit_result: 'Product engagement on the site increased by 23%',
    block_cases_metalwork: '3D-overview of metal structures',
    block_cases_metalwork_task: 'Increase the number of customers for the production of traverse',
    block_cases_metalwork_before: 'The product pages contained static pictures just like the competitors. Applications were extremely rare.',
    block_cases_metalwork_after: 'Interactive 3D-widgets of products with a clickable description of the main advantages of the company were introduced into the cards.',
    block_cases_metalwork_result: 'After a month of implementing the 3D-widget traverse sales increased by 37%.',
    block_cases_mishki: 'Case 0+ Media',
    block_cases_mishki_task: 'To introduce interactive 3D run-ins of cartoon characters into the website for partners for clarity',
    block_cases_mishki_before: '2D pictures of character poses',
    block_cases_mishki_after: '3D-widgets of heroes',
    block_cases_mishki_result: 'It has become easier to convey the images of cartoon characters to our partners.',
    block_cases_mockup: '3D-configurator of the mockup',
    block_cases_mockup_task: 'Give the designer\'s client the opportunity to view various mockup options using interactive 3D.',
    block_cases_mockup_before: '2D-mockup display',
    block_cases_mockup_after: '3D-mockup display',
    block_cases_mockup_result: 'The time to demonstrate the work using a 3D-widget has been halved.',
    block_cases_menu: '3D-menu for the website',
    block_cases_menu_task: 'Replace the boring slider of the main sections of the site with an interactive 3D-model that acts as a navigation menu.',
    block_cases_menu_before: 'Regular slider',
    block_cases_menu_after: '3D-widget',
    block_cases_menu_result: 'The average user engagement increased by 40%.',
    block_cases_more: 'Another case',
    block_cases_all: 'All cases',
    block_team_caption: 'Our team',
    block_team_description: 'Making 3D-technologies available to any business',
    block_team_owner_name: 'Aleksey Tkachenko',
    block_team_owner_post: 'Founder and developer',
    block_team_gruzdev_name: 'Aleksey Gruzdev',
    block_team_gruzdev_post: '3D designer',
    block_clients_caption: 'Our clients',
    block_clients_description: 'We are trusted by companies from different business sectors',
    block_clients_0plusmedia_caption: '0+ media',
    block_clients_sloros_caption: 'Sloros',
    block_clients_lesenka_caption: 'Lesenka',
    block_clients_kgorod_caption: 'Krasiviy gorod',
    block_clients_architent_caption: 'ArchiTent',
    block_clients_madkit_caption: 'Madkit',
    block_clients_glode_caption: 'Glode',
    block_clients_vesservice_caption: 'Nevskie vesi',
    block_clients_kkkm_caption: 'Krasnoyarsk Museum of Local Lore',
    block_clients_arbatskazka_caption: 'Arbatskaya skazka',
    block_clients_kaflon_caption: 'Kaflon',
    block_clients_istok_caption: 'Istok',
    block_clients_darexhouse_caption: 'Darex House',
    block_clients_artxpro_caption: 'ARTxPRO',
    block_clients_gorodovoy_caption: 'Gorodovoy',
    block_clients_bestcorian_caption: 'BestCorian',
    block_clients_consultbearings_caption: 'Consult Bearings',
    block_clients_berucottage_caption: 'Berucottage',
    block_reviews_caption: 'Reviews',
    block_reviews_description: 'Our mission is to help businesses grow in profit',
    block_reviews_lesenka_name: 'Egor Kotov',
    block_reviews_lesenka_post: '«Lesenka»',
    block_reviews_lesenka_text: 'The idea came up to place 3D models of stairs on the site, we settled on Site3D Configurator. I liked that there are a lot of settings. Technical support at a high level, not only helped with the settings, but also suggested how to prepare 3D models. It is very easy to integrate into the site. Plus, there is a demo period to test and verify everything. I recommend.',
    block_reviews_0plusmedia_name: 'Nika',
    block_reviews_0plusmedia_post: 'Brand manager 0+ Media',
    block_reviews_0plusmedia_text: 'The service is very convenient to use, you can independently adjust the parameters of the 3D figurine scene without being an expert. Everything is seamlessly integrated into the site on the Tilda.',
    block_reviews_namero_name: 'Ivan',
    block_reviews_namero_post: 'Founder of Namero Design',
    block_reviews_namero_text: 'You have a cool tool, thank you. I have been looking for something like this for a long time. All questions were answered in detail and intelligibly.',
    block_reviews_stendi_name: 'Valery',
    block_reviews_stendi_post: 'Project «Smart stands»',
    block_reviews_stendi_text: 'The service is 10 out of 10, we actively use it in our work. We are very satisfied with the service and technical support of this service!',
    block_reviews_royalcourt_name: 'Tamerlane',
    block_reviews_royalcourt_post: 'Real estate agency «Royal Court»',
    block_reviews_royalcourt_text: 'I liked the service! They worked very loyally, modernly and qualitatively. You can visually familiarize yourself at royalcourt.ru.',
    block_reviews_rapts_name: 'Algis',
    block_reviews_rapts_post: 'Director of the Digital Technology Department of the Association of Pipeline System Manufacturers',
    block_reviews_rapts_text: 'Excellent russian service. There is still something to refine in terms of functionality, but technical support is on top.',
    block_reviews_en_komplekt_name: 'Dmitry',
    block_reviews_en_komplekt_post: '«Energo-komplekt SPB»',
    block_reviews_en_komplekt_text: 'We turned to this service on the advice of our system administrator. The order layout was done quickly. The 3D-model works with a bang on the site. I recommend you to apply.',
    block_reviews_more: 'More reviews',
    block_reviews_other: '<p>Reviews on third-party sites:</p>' +
      '<ul>' +
      '<li><a href="https://yandex.ru/profile/77603470901" target="_blank">Yandex</a></li>' +
      '<li><a href="https://2gis.ru/spb/firm/70000001077787190" target="_blank">2GIS</a></li>' +
      '</ul>',
    block_quality_caption: 'Realistic graphics',
    block_quality_description: 'Maximum immersion in your product',
    block_ar_caption: 'Augmented reality',
    block_ar_description: 'Move things in the real environment',
    block_cost_caption: 'Tariffs',
    block_cost_description: 'Unlimited opportunities at anti-crisis prices',
    block_cost_individual_caption: 'Are you not satisfied with the tariffs or do you want to implement 3D on your site turnkey?',
    block_cost_individual_button: 'Choose an individual solution',
    block_cost_individual_link: 'https://b24-chbx01.bitrix24.site/crm_form_i2vqp',
    block_development_caption: 'Do you need a custom 3D-configurator?',
    block_development_description: 'We will implement any of your ideas',
    block_development_text: '<p>Write to us <a href="/support">in support</a> to discuss an individual solution</p>',
    block_contacts_caption: 'Contact us',
    block_contacts_description: 'We are in touch daily from 9:00 to 19:00 Moscow time',
    block_seo_text: '' +
      '<article class="article">' +
      '<section>' +
      '<p>Get to know with the presentations of our service for individual industries:</p>' +
      '<ul>' +
      '<li><a href="/houses">Houses</a></li>' +
      '<li><a href="/pavilions">Pavilions</a></li>' +
      '<li><a href="/stairs">Stairs</a></li>' +
      '<li><a href="/playgrounds">Playgrounds</a></li>' +
      '<li><a href="/fireplaces">Fireplaces</a></li>' +
      '<li><a href="/industry">Industrial equipment</a></li>' +
      '</ul>' +
      '</section>' +
      '</article>',
    branding: 'Branding',
    branding_description: '<p>To change the appearance of the 3D-widget to your company\'s style on a large scale, place the style.css style file and the necessary graphic files in the zip archive (any nesting of directories can be used for them).</p>' +
      '<p>We recommend watching <a href="https://configurator.site3d.site/docs/branding.zip" target="_blank">example</a> with comments.</p>' +
      '<p>For more detailed information, please contact <a href="/support">to support</a>.</p>',
    branding_saving: 'Uploading branding',
    channels_telegram_url: 'https://t.me/site3d_configurator',
    channels_telegram_title: 'Telegram',
    channels_vk_url: 'https://vk.com/site3d_configurator',
    channels_vk_title: 'Vkontakte',
    channels_zen_url: 'https://zen.yandex.ru/id/5d0f8b71ef7fdf00afad5234',
    channels_zen_title: 'Yandex Dzen',
    channels_rutube_url: 'https://rutube.ru/channel/24348974',
    channels_rutube_title: 'RuTube',
    channels_youtube_url: 'https://www.youtube.com/@site3d_configurator',
    channels_youtube_title: 'YouTube',
    code: 'Embed on the site',
    code_id: 'Project ID: {id}',
    code_any: 'For any website',
    code_domain: 'Domains (without http, www, slashes)',
    code_domain_hint: 'Configurator will work only in our domains. They should be separated by commas.',
    code_height: 'Widget height',
    code_height_hint: 'By default, the widget height is 500 pixels. It can be stretched to the full width of the container (100%), inside which you will place the widget. You can also specify a different height value in pixels.',
    code_height_default: 'By default',
    code_height_percent: '100%',
    code_height_pixel: 'Specify',
    code_container: 'Container for widget',
    code_container_hint: 'There can be several containers for widgets.',
    code_container_type_1: 'Standard',
    code_container_type_2: 'With the product code',
    code_container_type_2_warning: 'It is necessary to specify the product code at the first step',
    code_script: 'Script for loading all widgets',
    code_script_hint: 'The script should be only one on the page and be located after all containers for widgets.',
    code_copy: 'Copy the code',
    code_copy_all: 'Copy all code',
    code_copy_all_hint: 'If only one widget is planned on the page.',
    code_plugin_link: 'Link to plugin',
    code_component_link: 'Link to component',
    code_copy_success: 'The code has been successfully copied to the clipboard',
    code_wordpress: 'For Wordpress CMS',
    code_wordpress_copy: 'Copy the shortcode',
    code_moguta: 'For Moguta CMS',
    code_creatium: 'For Creatium',
    code_link: 'Link to view',
    code_link_loading: 'Uploading data for viewing …',
    code_link_text: 'Link to view the project',
    code_link_update: 'Update access by link',
    code_link_update_confirm: 'Do you really need to update the link access? The previous links will stop working.',
    code_link_update_success: 'Link access has been successfully updated',
    code_link_copy: 'Copy the link',
    code_link_copy_success: 'The link has been successfully copied to the clipboard',
    commerce_caption: 'Expand the possibilities',
    commerce_description: 'For commercial use of the service, it is necessary to <a href="/buy">buy one of the paid tariffs</a>',
    common_settings: 'Common settings',
    configurator_caption: 'Project name',
    configurator_caption_hint: 'Maximum 200 symbols',
    configurator_caption_init: 'Do you really want to clone this project?',
    configurator_category: 'Category',
    configurator_categories_caption: 'Categories',
    configurator_categories_default: 'Without category',
    configurator_categories_empty: 'Nothing categories',
    configurator_categories_remove_confirm: 'Do you really want to remove this category?',
    configurator_categories_edit_caption: 'Category name',
    configurator_clone_confirm: 'Действительно хотите создать копию данного проекта?',
    configurator_domains_caption: 'Domains',
    configurator_domains_default: 'Without domain',
    configurator_domains_empty: 'Domains have not been added yet',
    configurator_domains_remove_confirm: 'Do you really want to remove this domain?',
    configurator_domains_edit_caption: 'Domain (without http, www, slashes)',
    configurator_list_caption: 'Projects',
    configurator_list_empty: 'You do not have any projects yet',
    configurator_list_remove_confirm: 'Do you really want to remove the project <span class="hot">{name}</span>?',
    configurator_list_clone_confirm: 'Do you really want to clone the project <span class="hot">{name}</span>?',
    configurator_list_return: 'To the list',
    configurator_material_tags_caption: 'Material tags',
    configurator_material_tags_default: 'Without tags',
    configurator_material_tags_empty: 'Nothing material tags',
    configurator_material_tags_remove_confirm: 'Do you really want to remove this material tag?',
    configurator_optimizing_read: 'Reading the model file',
    configurator_optimizing_prepare: 'Preparing a model for a 3D scene',
    configurator_optimizing_save: 'Saving the model in an optimized form',
    configurator_saving: 'Project is saving',
    configurator_scene_to_other_description: 'Attention! The scene settings of the current project will be applied to all your projects. Before starting, you need to save all the changes you have made in this project.',
    configurator_scene_to_other_items: 'Copied settings',
    configurator_scene_to_other_confirm: 'Do you really apply the scene settings to other projects?',
    configurators_edit: 'Edit projects',
    configurators_saving: 'Projects is saving',
    configurators_export_letter_dealer: 'A letter to a dealer',
    configurators_export_letter_dealer_caption: 'Increase sales with 3D-models',
    configurators_export_letter_dealer_text: '<p>Dear dealer, we offer to introduce 3D models of our products on your website.</p>' +
      '<p>Read about the benefits, stages and cost of implementation here: <a href="https://configurator.site3d.site/docs/dealer_en.pdf" target="_blank">https://configurator.site3d.site/docs/dealer_en.pdf</a>.</p>',
    configurators_export_instruction: 'Instructions for embedding on the site',
    configurators_export_instruction_id: 'Project ID',
    configurators_export_instruction_clientCode: 'Product code',
    configurators_export_instruction_container_code: 'The code of the container to insert on the site',
    configurators_export_instruction_script_code: 'The script code to insert on the site before the closing body tag',
    configurators_export_instruction_link: 'Link to view in a separate browser window',
    contacts: '<div class="contacts__block">' +
      '<h3 class="contacts__blockCaption">Offline</h3>' +
      '<div class="contacts__blockContent">' +
      '<p>197022, St. Petersburg, Prospekt Medikov, house 3, lit. A</p>' +
      '<p><span class="hot">by prior agreement</span></p>' +
      '</div>' +
      '</div>' +
      '<div class="contacts__block">' +
      '<h3 class="contacts__blockCaption">Online</h3>' +
      '<div class="contacts__blockContent">' +
      '<p>Contact us for any questions:</p>' +
      '<p><a class="contacts__blockItem contacts__blockItem_email" href="mailto:info@site3d.site">info@site3d.site</a></p>' +
      '<p><a class="contacts__blockItem contacts__blockItem_telegram" href="https://t.me/site3d_support" target="_blank">@site3d_support</a></p>' +
      '</div>' +
      '</div>',
    copy_id: 'Copy the project ID',
    copy_id_success: 'The project ID has been successfully copied to the clipboard',
    design: 'Design',
    design_theme: 'Theme',
    design_theme_default: 'Site3D',
    design_theme_lite: 'Lite',
    design_appearance: 'Styling',
    design_appearance_apply_to_other_confirm: 'Do you really want to apply styling to other projects (it is required to save the project beforehand)?',
    design_font_family: 'Font',
    design_border_radius: 'Rounding widget corners',
    design_background_color: 'Scene color',
    design_preload: 'Before startup',
    design_preload_background_color: 'Background color',
    design_preload_background_image: 'Background image',
    design_preload_poster: 'Start button in the form of an image',
    design_preload_button: 'Standard start button',
    design_logo: 'Service logotype',
    design_logo_hint: 'Service logotype editing is not available on the free plan',
    design_logo_is_enabled: 'Show',
    design_logo_enabled_time: 'Time of appearance',
    design_logo_enabled_time_start: 'At the start',
    design_logo_enabled_time_hover: 'On hover',
    design_watermark: 'Watermark',
    docs_user_agreement: 'User agreement',
    docs_public_offer: 'Public offer',
    docs_telegram: 'Pack of <a href="https://t.me/addstickers/Site3dConfigurator" target="_blank">stickers</a> and <a href="https://t.me/addstickers/Site3dConfiguratorEmoji" target="_blank">emojis</a> in telegram',
    export_image: 'Image',
    export_image_no_widget: 'Before creating an image, you need to load the widget',
    export_video: 'Video',
    export_video_no_widget: 'Before creating a video, you need to load the widget',
    export_video_duration: 'The duration of the video',
    file_load: 'File is loading',
    file_wrong_format: 'Valid formats: {formats}',
    file_wrong_model_size: 'Now the maximum allowable size of the model: {maxSize}\u00a0Mb. You can increase this value by writing to <a href="/support">our support</a>. We can also help you optimize your model to reduce its size.',
    ile_wrong_texture_size: 'The maximum allowed size of the texture: {maxSize}\u00a0Mb. The optimal texture resolution for most cases is 1024 pixels on the long side. We recommend saving the texture with compression in jpg format.',
    footer_reestr: 'Included in the register of Russian software',
    footer_help: 'Support',
    footer_help_guide: 'Guide',
    footer_channels: 'Our channels',
    form_need_fill_inputs: 'You need to fill all required fields',
    form_step: 'Step',
    form_step_model: 'Model loading',
    form_step_scene: 'Scene parameters',
    form_step_parts: 'Parts',
    form_step_export: 'Export',
    form_save: 'Save',
    graphics_quality: 'Quality',
    graphics_alpha: 'Transparency',
    graphics_bloom: 'Bloom',
    graphics_bloom_strength: 'Strength',
    graphics_bloom_radius: 'Radius',
    graphics_bloom_threshold: 'Threshold',
    graphics_color: 'Color balance',
    graphics_color_r: 'Red',
    graphics_color_g: 'Green',
    graphics_color_b: 'Blue',
    graphics_brightness: 'Brightness',
    graphics_contrast: 'Contrast',
    graphics_shadows: 'Shadows',
    graphics_optimize: 'Optimize the model',
    graphics_screen_size_correction: 'Hide small parts of the model',
    graphics_auto_correction: 'Autocorrection',
    graphics_auto_correction_hint: 'The service will underestimate the quality of graphics for weak devices in order to maintain an optimal response rate to user actions.',
    graphics_common_material: 'Common material of the model',
    graphics_edges: 'Edges',
    graphics_edges_hint: 'To display the faces correctly, we recommend following our tips when preparing the model (see <a href="/docs/guide_en.pdf" target="_blank">our manual</a>)',
    header_try: 'Try for free',
    help_telegram_url: 'https://t.me/site3d_support',
    help_telegram_title: 'Telegram',
    help_email: 'info@site3d.site',
    help_email_url: 'mailto:info@site3d.site',
    help_email_title: 'E-mail',
    hint_subdomains: '<p>Your site has a domain name, for example, site.ru. You can create as many sites as you want in the form of subdomains. For example, page1.site.ru, page2.site.ru etc.</p>',
    hint_ar: '<p>With the help of augmented reality technology, you can try on the product directly from the website in a real environment. For example, you can see how the sofa in your room will look like.</p>',
    hint_mockups: '<p>Using our service, you can automatically create mockups in the form of 2D-images with transparent backgrounds or videos. This way you can complement your product cards not only with interactive 3D-viewing, but also with a variety of pictures and video reviews.</p>',
    hint_scene_to_other: '<p>You have the ability to customize the view for a large number of your projects at once so that your 3D-widgets are in the same style.</p>',
    hint_configuring: '<p>Configuring includes:</p>' +
      '<ul>' +
      '<li>Changing colors, textures and configurations</li>' +
      '<li>Tooltips and links connected to specific parts of the product</li>' +
      '<li>Cost calculation and order form</li>' +
      '</ul>',
    hint_mass_editing: '<p>If you have a lot of products, then you can archive all the 3D-models and automatically create many projects to insert them on the site</p>' +
      '<p>Up to 1000 products can be uploaded as part of the tariff. The ability to automatically add additional 1000 projects &mdash; 10&nbsp;000&nbsp;₽ per&nbsp;year.</p>',
    hint_branding: '<p>Branding &mdash; creating an individual widget theme according to your corporate identity:</p>' +
      '<ul>' +
      '<li>Replacing the appearance of the download process</li>' +
      '<li>Replacing control element icons</li>' +
      '<li>Other changes to the user interface by agreement</li>' +
      '</ul>',
    hint_priority_support: '<p>Priority support includes:</p>' +
      '<ul>' +
      '<li>Training your designers to prepare 3D-models</li>' +
      '<li>Training your programmers on the implementation of 3D-models in the site</li>' +
      '</ul>',
    input_tags_placeholder: 'Use a comma or an input for adding',
    materials_edit: 'Catalog of general materials',
    materials_saving: 'Materials are saving',
    materials_common: 'Common material',
    materials_global: 'Material from library',
    materials_item_selected: 'Selected material: <span class="hot">{name}</span>',
    material_type_texture_maps: 'For textures and maps',
    material_type_only_texture: 'Only for textures',
    menu_main_caption: 'Main',
    menu_cases_caption: 'Cases',
    menu_reviews_caption: 'Reviews',
    menu_news_caption: 'News',
    menu_posts_caption: 'Blog',
    menu_faq_caption: 'FAQ',
    menu_account_caption: 'Account',
    menu_buy_caption: 'Tariffs',
    menu_partners_caption: 'For partners',
    menu_api_caption: 'For developers',
    menu_support_caption: 'Support',
    model_file: 'Model',
    model_file_hint: '<p>To create or search for a ready-made model, we recommend using <span class="hot">glb</span> format.</p>' +
      '<p>If the format of your model is not supported by our service, you can:</p>' +
      '<ul>' +
      '<li>Export in the usual 3D-editor according to <a href="/docs/guide_en.pdf" target="_blank">our instructions</a></li>' +
      '<li>Contact to our <a href="/support" target="_blank">support</a>, we will help you with the conversion</li>' +
      '</ul>' +
      '<p>If the downloaded model file requires auxiliary files (for example, textures), it is necessary to pack all files into a <span class="hot">zip archive</span>.</p>' +
      '<p>Also, to improve the viewing speed, you can attach more detailed versions of the entire model or only its individual elements to the archive.</p>' +
      '<p>These files should be named in such a way that, after sorting, they follow the main model file and then from less detailed versions to more detailed ones.</p>' +
      '<p>Such models will be loaded only when the camera approaches the original model.</p>',
    model_file_catalog_hint: 'if you do not find the right option, then we can add it for free upon request <a href="/support/en">to support</a>.',
    model_file_info_caption: 'About the model',
    model_file_info_triangles: 'Polygons: <span class="hot">{value}</span>',
    model_file_info_parts_all: 'All elements: <span class="hot">{value}</span>',
    model_file_info_parts_unique: 'Unique elements: <span class="hot">{value}</span>',
    model_file_settings: 'Model upload settings',
    model_file_convert: 'Save to glb',
    model_file_optimize: 'Optimize',
    model_file_auto_center: 'Centering',
    model_file_encrypt: 'Encrypt',
    model_file_encrypt_hint: 'Encryption protects the model from downloading, but increases the weight of the model and the loading time',
    model_file_compress: 'Compress',
    model_file_compress_hint: 'In rare cases, compression can lead to incorrect display of the model. In this case, you need to disable it before sending the model file.',
    model_file_unexpected: 'If you expected a different result, disable all the items from the model upload settings (located above) and re-upload your 3D-model',
    model_site3d: 'Site3D logotype',
    model_house: 'House',
    move_camera_is_enabled: 'Turn on',
    move_camera_pos_from_model: 'Start view',
    move_camera_pos_from_model_hint: 'Distance from the part of the model in % and rotations',
    move_camera_model_person: 'The person in relation to the label',
    move_camera_scale_range: 'Zoom range',
    move_camera_rotate_range: 'Viewing angles',
    move_camera_rotate_range_hint: 'Freedom of view after moving to the label',
    move_camera_use_target: 'Rotate around the model',
    move_camera_open_window: 'Open the window after moving',
    move_camera_anim_variant: 'Play an animation variant',
    news_not_found: 'No news',
    news_loading: 'The news section is loading',
    news_content_not_found: 'The news was not found',
    news_content_loading: 'The news is loading',
    news_content_all: 'All news',
    order_info: 'Order form',
    order_info_is_visible: 'Show',
    parts_hover: 'Name of the selected part of the model <span class="hot">{name}</span>',
    parts_hint: 'Click on the part of the model you want to specify colors, materials or a label (<span class="hot">{count}</span> of the most voluminous parts are processed).',
    parts_no_hint: 'The model has no separate parts',
    parts_select: 'Select an action on a part of the model <span class="hot">{name}</span>',
    parts_settings_caption: 'Settings',
    parts_settings_groups_empty: 'Groups have not been added yet',
    parts_settings_groups_default: 'Without group',
    parts_settings_groups_remove_confirm: 'Do you really want to remove this group?',
    parts_settings_empty: 'Settings have not been added yet',
    parts_settings_exist: 'Such setting already exists for this part of the model',
    parts_settings_many_materials: 'To edit the color or material, a part of the model must have only one material',
    parts_settings_colors_add: 'Add colors',
    parts_settings_colors_edit: 'Edit colors',
    parts_settings_colors_elements_values: 'List',
    parts_settings_colors_elements_palette: 'Palette',
    parts_settings_colors_elements_values_palette: 'List and palette',
    parts_settings_colors_empty: 'Colors have not been added yet',
    parts_settings_colors_value_exist: 'This color has already been added',
    parts_settings_colors_remove_confirm: 'Do you really want to remove this color?',
    parts_settings_materials_add: 'Add materials',
    parts_settings_materials_edit: 'Edit materials',
    parts_settings_materials_empty: 'Materials have not been added yet',
    parts_settings_materials_remove_confirm: 'Do you really want to remove this material?',
    parts_settings_models_add: 'Add models',
    parts_settings_models_edit: 'Edit models',
    parts_settings_models_empty: 'Models have not been added yet',
    parts_settings_models_remove_confirm: 'Do you really want to remove this model?',
    parts_settings_models_part_hint: 'Here you can specify the names of the parts of the model that will be displayed when this option is selected. At the same time, parts of models whose names are indicated in this field for other variants will be hidden.',
    parts_settings_toggle_add: 'Add visibility switch',
    parts_settings_toggle_edit: 'Edit visibility switch',
    parts_settings_toggle_value: 'Show',
    parts_settings_size_add: 'Add size',
    parts_settings_size_edit: 'Edit size',
    parts_settings_remove_confirm: 'Do you really want to remove this setting?',
    parts_labels: 'Labels',
    parts_labels_hint: 'Higher labels in the list block the visibility of lower labels.',
    parts_labels_empty: 'Labels have not been added yet',
    parts_labels_add: 'Add label',
    parts_labels_edit: 'Edit label',
    parts_labels_auto_start: 'Show at startup',
    parts_labels_button_text: 'Button text',
    parts_labels_button_text_hint: 'Instead of an empty text there will be an icon',
    parts_labels_link: 'Link',
    parts_labels_link_url: 'URL',
    parts_labels_link_text: 'Text',
    parts_labels_pos: 'Position',
    parts_labels_align: 'Align',
    parts_labels_align_button: 'Button',
    parts_labels_align_window: 'Window',
    parts_labels_move_camera: 'Moving the the label',
    parts_labels_common_styles: 'Common styles settings',
    parts_labels_styles_button: 'Startup view',
    parts_labels_styles_window: 'Popup window',
    parts_labels_styles_window_main: 'Main',
    parts_labels_styles_window_caption: 'Caption',
    parts_labels_styles_window_text: 'Text',
    parts_labels_styles_window_link: 'Link',
    parts_labels_remove_confirm: 'Do you really want to remove this label?',
    parts_links: 'Links',
    parts_links_empty: 'Links have not been added yet',
    parts_links_add: 'Add link',
    parts_links_edit: 'Edit link',
    parts_links_url: 'URL',
    parts_links_hint: 'Hint',
    parts_links_selection: 'Selection',
    parts_links_selection_common_selection: 'Common selection settings',
    parts_links_selection_color: 'Color',
    parts_links_selection_thickness: 'Thickness',
    parts_links_styles_hint: 'Hint',
    parts_links_remove_confirm: 'Do you really want to remove this link?',
    page_main_caption: 'Site3D Configurator',
    page_main_description: 'Service for a quick creation of 3D-configurators',
    page_main_title: 'Site3D Configurator - show a 3D-model or configurator on the website',
    page_main_meta_description: 'Service allows you to embed an interactive widget with a 3D-model of a product or service into a website with support for configuration (changing colors, textures, replacing some parts of the model with others, playing animations, and much more).',
    page_cases_caption: 'Our cases',
    page_cases_description: 'We benefit the business. Expanding boundaries of possibilities.',
    page_cases_title: 'Site3D Configurator - Cases',
    page_cases_meta_description: 'Examples of using the online 3D-model service Site3D Configurator (changing colors, textures, replacing some parts of the model with others, playing animations, and much more).',
    page_reviews_caption: 'Reviews',
    page_reviews_description: 'Our mission is to help businesses grow in profit',
    page_reviews_title: 'Site3D Configurator - Reviews',
    page_reviews_meta_description: 'Reviews of online 3D-model service Site3D Configurator (changing colors, textures, replacing some parts of the model with others, playing animations, and much more).',
    page_news_caption: 'News',
    page_news_description: 'Opening up new opportunities for your business',
    page_news_title: 'Site3D Configurator - News',
    page_news_meta_description: 'News of the Site3D Configurator cloud service.',
    page_posts_caption: 'Blog',
    page_posts_description: 'All the most interesting things for 3D-implementation in your business',
    page_posts_title: 'Site3D Configurator - Blog',
    page_faq_caption: 'Frequently Asked Questions',
    page_faq_description: 'We have collected answers to the most popular questions for you in one place',
    page_posts_meta_description: 'Blog of the Site3D Configurator cloud service.',
    page_faq_title: 'Site3D Configurator - FAQ',
    page_faq_meta_description: 'Frequently Asked Questions of the Site3D Configurator cloud service.',
    page_account_caption: 'Personal account',
    page_account_button_login: 'Login / Sign up',
    page_account_button_enter: 'My account',
    page_profile_caption: 'Profile',
    page_buy_caption: 'Subscription payment',
    page_buy_success: '<p>Congratulations! The payment was successful. Your tariff will be activated within 24 hours after the payment is received.</p><p>If you paid for a subscription without authorization, please contact us at support to we have linked your payment to your account.</p><p><a href="/buy/en">Pay for another tariff</a></p>',
    page_buy_fail: '<p>Unfortunately, the payment failed. Please <a href="/buy/en">try again</a> or write to us in <a href="/support/en">support</a>.</p>',
    page_partners_caption: 'For partners',
    page_partners_description: 'Earn on the latest technologies',
    page_partners_content:
      '<div class="textBlocks">' +
      '<div class="textBlocks__item">' +
      '<h2>Partnership program</h2>' +
      '<h3>Who is the program for?</h3>' +
      '<ul>' +
      '  <li>Web studios and Internet agencies</li>' +
      '  <li>Freelancers providing website development and web design services</li>' +
      '</ul>' +
      '<h3>What will you get?</h3>' +
      '<ul>' +
      '  <li>50% of all purchases of our service by your customers</li>' +
      '  <li>100% from the introduction of the service to your customers\' websites (expand your range of services)</li>' +
      '</ul>' +
      '<h3>How to become a partner?</h3>' +
      '<ul>' +
      '  <li><a href="/reg">Register</a> in our service</li>' +
      '  <li>Bring your first customer and write to us <a href="/support">in support</a></li>' +
      '</ul>' +
      '<h3>How to proceed after confirming the partnership?</h3>' +
      '<ul>' +
      '  <li>You receive 100% subscription payment from the client at the prices specified in the <a href="/buy">payment section</a></li>' +
      '  <li>Log in to our service, go to the <a href="/buy">payment page</a> and pay for a subscription for a client with a 50% discount</li>' +
      '  <li>Place the projects on the client\'s website</li>' +
      '</ul>' +
      '</div>' +
      '<div class="textBlocks__item">' +
      '<h2>Referral program</h2>' +
      '<h3>Who is the program for?</h3>' +
      '<p>Anyone who wants to earn money by attracting customers to our service.</p>' +
      '<h3>What will you get?</h3>' +
      '<ul>' +
      '  <li>50% off our client\'s annual subscription</li>' +
      '  <li>Additional customers by posting information about your services in our platform</li>' +
      '</ul>' +
      '<h3>How to start earning?</h3>' +
      '<ul>' +
      '  <li><a href="/reg">Register</a> in our service</li>' +
      '  <li>Copy the referral link in the user profile</li>' +
      '  <li>Place a link next to the description of your services on your sites</li>' +
      '  <li>After paying for the subscription of the client who clicked on your link, we contact you and transfer the reward to you in a way that is convenient for you</li>' +
      '</ul>' +
      '</div>' +
      '</div>',
    page_api_caption: 'For developers',
    page_api_description: 'Make our 3D-widget interact more closely with your business',
    page_support_caption: 'Support',
    page_support_description: 'We will promptly solve the problem of implementing interactive 3D on your website',
    page_reg_caption: 'Registration',
    page_admin_caption: 'Admin panel',
    page_start_caption: 'Quick start',
    page_error_caption: 'Something went wrong',
    page_error_content: 'Please contact support. We will promptly solve your problem.',
    page_notFound_caption: 'Page is not found',
    page_notFound_content: 'Please try the menu for navigation',
    phrase_activate: 'Activate',
    phrase_add: 'Add',
    phrase_alias: 'Alias',
    phrase_align: 'Align',
    phrase_all: 'All',
    phrase_anim_variant: 'Animation variant',
    phrase_apply_to_other: 'Apply to other projects',
    phrase_auto_start: 'Show at startup',
    phrase_axes: 'Axes',
    phrase_axe_x: 'Axe X',
    phrase_axe_y: 'Axe Y',
    phrase_axe_z: 'Axe Z',
    phrase_back: 'Back',
    phrase_cancel: 'Cancel',
    phrase_caption: 'Caption',
    phrase_caption_ru: 'Caption (ru)',
    phrase_caption_en: 'Caption (en)',
    phrase_categories: 'Categories',
    phrase_category: 'Category',
    phrase_client_code: 'Product code',
    phrase_clone: 'Create copy',
    phrase_clone_complete: 'Copy creation has been completed',
    phrase_close: 'Close',
    phrase_colors: 'Colors',
    phrase_color: 'Color',
    phrase_color_any: 'Any color',
    phrase_color_init: 'Color by default',
    phrase_color_schemes: 'Color schemes',
    phrase_color_values: 'List of colors',
    phrase_company: 'Company',
    phrase_configurators: 'Projects',
    phrase_copyright: '© 2021-2024. Site3D LLC',
    phrase_copy: 'Copy',
    phrase_copy_item: 'Copy item',
    phrase_change_size: 'Change size',
    phrase_create: 'Create',
    phrase_creating: 'Creating',
    phrase_date: 'Date',
    phrase_date_create: 'Create date',
    phrase_date_update: 'Update date',
    phrase_date_start: 'Start date',
    phrase_date_end: 'End date',
    phrase_default: 'Default',
    phrase_deselect_all: 'Deselect all',
    phrase_description: 'Description',
    phrase_description_ru: 'Description (ru)',
    phrase_description_en: 'Description (en)',
    phrase_detail: 'Detail',
    phrase_domain: 'Domain',
    phrase_domains: 'Domains',
    phrase_drop_here: 'Drop the file here ...',
    phrase_drop_or_click: 'Drag and drop a file <span class="hot">{formats}</span> here or click to select it',
    phrase_edit: 'Edit',
    phrase_editing: 'Editing',
    phrase_email: 'E-mail',
    phrase_empty: 'Empty',
    phrase_enter: 'Enter',
    phrase_fading: 'Fading',
    phrase_file_load_from_file: 'Your file',
    phrase_file_load_from_variants: 'From the catalog',
    phrase_file_download: 'Download file',
    phrase_file_downloading: 'Downloading file',
    phrase_file_loaded: 'File is loaded',
    phrase_file_not_loaded: 'File is not loaded',
    phrase_file_open: 'Open file',
    phrase_file_variants: 'Catalog',
    phrase_file_remove_confirm: 'Do you really want to delete the file?',
    phrase_filter: 'Filter',
    phrase_font_color: 'Font color',
    phrase_font_size: 'Font size',
    phrase_further: 'Further',
    phrase_group: 'Group',
    phrase_group_toggle: 'Group with one active switch',
    phrase_guide: 'Guide',
    phrase_height_map: 'Height map',
    phrase_roughness_map: 'Roughness map',
    phrase_ambient_map: 'Ambient occlusion map',
    phrase_hint: 'Hint',
    phrase_icon: 'Icon',
    phrase_id: 'ID',
    phrase_image: 'Image',
    phrase_inside_label: 'Inside a label',
    phrase_keep_proportions: 'Keep the proportions',
    phrase_load: 'Load',
    phrase_loading: 'Loading',
    phrase_material: 'Material',
    phrase_material_edited: 'Uploading your own texture',
    phrase_material_create: 'Creating your own texture',
    phrase_material_copy: 'Copy variants of the material',
    phrase_material_copy_hint: 'You can copy all the material items and paste them into the material settings for this or another part of the model.',
    phrase_material_maps: 'Maps',
    phrase_material_is_common_maps: 'Use common maps',
    phrase_material_tag: 'Material tags',
    phrase_max_length: 'Minimum length',
    phrase_menu: 'Menu',
    phrase_min_square: 'Minimum square',
    phrase_model: 'Model',
    phrase_model_part: 'Names of model parts',
    phrase_model_part_hint: 'The names are set inside the 3D-model. To apply the setting to several parts of the model, you need to specify a common part of the name for them or list them separated by commas.',
    phrase_model_part_simple: 'Model part name',
    phrase_model_part_simple_hint: 'The names are set inside the 3D-model.',
    phrase_models_move: 'Moving mode',
    phrase_models_move_hint: 'In this case, it will be possible to add and move models over the surface of the specified parts of the project',
    phrase_move: 'Move',
    phrase_move_up: 'Move up',
    phrase_move_down: 'Move down',
    phrase_name: 'Name',
    phrase_new_project: 'Create new project',
    phrase_no: 'No',
    phrase_not_found: 'Nothing is found',
    phrase_not_selected: 'Nothing is selected',
    phrase_ok: 'Ok',
    phrase_opacity: 'Opacity',
    phrase_paste: 'Paste',
    phrase_paste_item: 'Paste item',
    phrase_physical_properties: 'Physical properties',
    phrase_physical_properties_metalness: 'Metalness',
    phrase_physical_properties_roughness: 'Roughness',
    phrase_physical_properties_emissive: 'Luminosity',
    phrase_pos: 'Position',
    phrase_preload_caption: 'Title before loading the widget',
    phrase_price: 'Price',
    phrase_quality: 'Quality',
    phrase_range: 'Range',
    phrase_refresh: 'Refresh',
    phrase_remove: 'Remove',
    phrase_repeat: 'Repeat',
    phrase_repeat_texture: 'Repeat texture',
    phrase_repeat_type: 'Repeat type',
    phrase_replace: 'Replace',
    phrase_resend: 'Resend',
    phrase_reset_to_original: 'Reset to the original',
    phrase_reset_to_original_confirm: 'Do you really want to return the original value?',
    phrase_reverse: 'In reverse',
    phrase_rotation: 'Rotation',
    phrase_rub: 'rub.',
    phrase_settings: 'Settings',
    phrase_save: 'Save',
    phrase_save_success: 'The data was successfully saved',
    phrase_scaling: 'Scaling',
    phrase_search: 'Search',
    phrase_seconds_short: 's',
    phrase_select_all: 'Select All',
    phrase_send: 'Send',
    phrase_set_geometry: 'Use only geometry',
    phrase_set_geometry_hint: 'When a separate model is loaded in the selected variant and this option is enabled, the geometry of the part of the model of the current setting is replaced by the geometry of the first part of the model of the variant.',
    phrase_show_button: 'Show button',
    phrase_size: 'Size',
    phrase_size_length: 'Length',
    phrase_size_height: 'Height',
    phrase_size_width: 'Width',
    phrase_skybox: 'Panorama',
    phrase_sort: 'Sort',
    phrase_stat: 'Statistics',
    phrase_stat_activity: 'Activity',
    phrase_stat_rotations: 'Rotations',
    phrase_styles: 'Styles',
    phrase_styles_buttons: 'Buttons',
    phrase_styles_panels: 'Panels',
    phrase_styles_window: 'Window',
    phrase_styles_colors_primary: 'Main content colors',
    phrase_styles_bg_colors_primary: 'Main background colors',
    phrase_styles_colors_second: 'Additional content colors',
    phrase_styles_bg_colors_second: 'Additional background colors',
    phrase_styles_general: 'General element',
    phrase_styles_active: 'Active element',
    phrase_styles_selected: 'Selected element',
    phrase_styles_muted: 'Muted element',
    phrase_styles_disabled: 'Disabled element',
    phrase_styles_radius: 'Border radius',
    phrase_styles_saving: 'Saving styles',
    phrase_support: 'Support',
    phrase_support_description: '<p>First, we recommend to read <a href="/docs/guide_en.pdf" target="_blank">the user manual</a>.</p>' +
      '<p>Next, write to us about your task, and we will help you during the day.</p>' +
      '<ul><li>Telegram: <a href="https://t.me/site3d_support" target="_blank">@site3d_support</a></li><li>E-mail: <a href="mailto:info@site3d.site">info@site3d.site</a></li></ul>' +
      '<p>If it is necessary to expand the current <a href="/buy">tariffs</a>, an individual access cost calculation system is in place..</p>' +
      '<p>The software is distributed as an Internet service, no special actions are required to install the software on the user\'s side.</p>' +
      '<p>Foreign analogues that we replace: Sketchfab, Vectary.</p>' +
      '<p><a href="/docs/technical-documentation_ru.pdf" target="_blank">Technical documentation</a></p>',
    phrase_tags: 'Tags',
    phrase_tariff: 'Tariff',
    phrase_text: 'Text',
    phrase_text_ru: 'Message (ru)',
    phrase_text_en: 'Message (en)',
    phrase_texture: 'Texture',
    phrase_thickness: 'Thickness',
    phrase_toggle_on: 'Expand',
    phrase_toggle_off: 'Roll up',
    phrase_to_first_page: 'To the first page',
    phrase_to_last_page: 'To the last page',
    phrase_turn_on: 'Turn on',
    phrase_turn_off: 'Turn off',
    phrase_type: 'Type',
    phrase_value: 'Value',
    phrase_value_init: 'Init value',
    phrase_value_min: 'Minimum value',
    phrase_value_max: 'Maximum value',
    phrase_value_step: 'Step',
    phrase_view: 'View',
    phrase_view_tile: 'Tile',
    phrase_view_list: 'List',
    phrase_unit: 'Unit',
    phrase_url: 'URL',
    phrase_yes: 'Yes',
    phrase_yes_no_errors: 'Without errors',
    phrase_yes_errors: 'With errors',
    planner_ground: 'Ground',
    posts_not_found: 'Nothing posts',
    posts_loading: 'The posts section is loading',
    posts_content_not_found: 'The post was not found',
    posts_content_loading: 'The post is loading',
    posts_content_button_try: 'Try to add 3D to the website for free',
    posts_content_all: 'All posts',
    price_info: 'Price',
    price_info_is_visible: 'Show',
    price_info_init_value: 'Init value',
    price_info_currency: 'Currency',
    product_properties: 'Product properties',
    product_code_caption: 'Code',
    product_code_hint: 'It can be used to insert a widget on a website instead of a project ID.',
    profile_caption: 'Edit your profile',
    reg_caption: 'A little bit about yourself before start...',
    reg_send: 'To create 3D',
    reg_welcome: '<p>Welcome, {name}, to our service.</p>' +
      '<ul>' +
      '<li>Subscribe to our <a href="https://t.me/site3d_configurator" target="_blank">telegram channel</a></li>' +
      '<li>Send us <a href="https://t.me/site3d_support" target="_blank">in support</a> the word <span class="hot">GIFT</span></li>' +
      '<li>Get <span class="hot">10 days at the maximum rate</span></li>' +
      '</ul>',
    repeats_once: 'Once',
    repeats_loop: 'Loop',
    repeats_forward_and_back: 'Forward and back',
    rotate_correction_none: 'No',
    rotate_correction_x_90: '90° by X',
    rotate_correction_x_180: '180° by X',
    rotate_correction_x_270: '270° by X',
    rotate_correction_z_90: '90° by Z',
    rotate_correction_z_180: '180° by Z',
    rotate_correction_z_270: '270° by Z',
    rotate_directions_clockwise: 'Clockwise',
    rotate_directions_counterclockwise: 'Counterclockwise',
    scene_camera: 'The initial position of the camera',
    scene_auto_center: 'Place the model in the center',
    scene_bound_center: 'Fix the centers of the details',
    scene_bound_center_hint: 'It does not work for all cases (see <a href="/docs/guide_en.pdf" target="_blank">our guide</a> in the model preparation section)',
    scene_start_rotate: 'Camera rotation at startup',
    scene_start_help: 'Animated icons at startup',
    scene_auto_load: 'Automatic loading',
    scene_start: 'Start view',
    scene_nav_buttons: 'Navigation buttons',
    scene_nav_main_buttons: 'Main',
    scene_nav_additional_buttons: 'Additional',
    scene_nav_button_pos: 'Init view',
    scene_nav_button_full: 'Fullscreen',
    scene_nav_button_rotate: 'Autorotate',
    scene_nav_button_light: 'Light',
    scene_nav_button_anim: 'Animation',
    scene_nav_button_skybox: 'Panoramas',
    scene_nav_button_size: 'Size',
    scene_nav_button_label: 'Labels',
    scene_nav_button_vr: 'Virtual reality',
    scene_nav_button_ar: 'Augmented reality',
    scene_nav_button_share: 'Share PDF',
    scene_nav_button_link: 'Share link',
    scene_nav_button_download: 'Download the model',
    scene_nav_button_screenshot: 'Take a screenshot',
    scene_nav_link: 'Share a link',
    scene_nav_help: 'Help window',
    scene_nav_help_text: 'Message text',
    scene_nav_help_auto_start: 'Show at startup',
    scene_nav_start_show_buttons: 'Show buttons at startup',
    scene_nav_start_show_hot_ar: 'Show big AR button',
    scene_nav_start_show_panels: 'Show panels at startup',
    scene_pos_from_model_hint: 'Distance from the model in % and rotations',
    scene_use_target: 'Round around the model',
    scene_scale: 'Scaling',
    scene_scale_control_hint: 'The range of camera zoom and distance as a percentage of the model size',
    scene_pos_correction: 'Position correction',
    scene_size_correction: 'Size correction',
    scene_rotate: 'Rotation',
    scene_rotate_control_full: 'Without any restrictions',
    scene_rotate_correction: 'Rotate correction',
    scene_auto_rotate: 'Auto rotation',
    scene_auto_rotate_auto_start: 'Rotate at startup',
    scene_auto_rotate_direction: 'Direction',
    scene_auto_rotate_speed: 'Speed',
    scene_anim_control: 'Animation control',
    scene_anim_control_auto_start: 'Show at startup',
    scene_anim_control_active_by_click: 'Active by click',
    scene_anim_control_number: 'Number',
    scene_anim_control_number_not_found: 'Animation is not found',
    scene_anim_control_common_settings: 'Common animation settings',
    scene_anim_control_repeat: 'Repeat',
    scene_anim_control_speed: 'Speed',
    scene_anim_control_variants: 'Variants',
    scene_anim_control_variants_empty: 'Variants of animations have not been added yet',
    scene_anim_control_variants_remove_confirm: 'Do you really want to remove this variant of animations?',
    scene_anim_control_manual: 'Manual control',
    scene_anim_control_marks: 'Marks',
    scene_anim_control_marks_hint: 'You can show some pop-up text for a certain moment of the animation',
    scene_anim_control_marks_empty: 'Marks have not been added yet',
    scene_anim_control_marks_remove_confirm: 'Do you really want to remove this mark?',
    scene_anim_control_hover: 'Hover',
    scene_anim_control_show_panel: 'Show panel',
    scene_size_control: 'Size control',
    scene_size_control_auto_start: 'Show at startup',
    scene_size_control_unit: 'Unit of measurement',
    scene_size_control_recalc: 'Recalculate',
    scene_floor: 'Floor',
    scene_floor_view_rectangle: 'Rectangle',
    scene_floor_view_circle: 'Circle',
    scene_floor_scale: 'Scale',
    scene_floor_color: 'Color',
    scene_skybox: 'Panoramas',
    scene_skybox_hint: 'Images of 360 degrees in jpeg or png format. You can download <a href="https://polyhaven.com/hdris" target="_blank">here</a>.',
    scene_skybox_image: 'Image',
    scene_skybox_image_hint: 'Image of 360 degrees in jpeg or png format',
    scene_skybox_empty: 'Panoramas have not been added yet',
    scene_skybox_remove_confirm: 'Do you really want to remove this panorama?',
    scene_skybox_show_default: 'Panorama by default',
    scene_light: 'Light',
    scene_ambient_light: 'Ambient light',
    scene_ambient_light_hint: 'Power and color',
    scene_directional_light: 'Directional light above',
    scene_directional_light_up: 'Directional light below',
    scene_directional_light_hint: 'Power, angles and color',
    scene_ar: 'Augmented Reality',
    scene_graphics: 'Graphics',
    setting_size: 'Common size',
    skybox_field: 'Field',
    skybox_forrest: 'Forrest',
    start_model_loading: 'Model loading',
    start_model_load_failed: 'Failed to load the model',
    styles_padding: 'Padding from the edges',
    styles_fontSize: 'Font size',
    styles_fontWeight: 'Font weight',
    styles_fontWeight_normal: 'Normal',
    styles_fontWeight_bold: 'Bold',
    styles_color: 'Message color',
    styles_backgroundColor: 'Background color',
    styles_borderWidth: 'Border width',
    styles_borderColor: 'Border color',
    styles_borderRadius: 'Border radius',
    tags_no: 'Without tags',
    tags_empty: 'No tags',
    tags_remove_confirm: 'Do you really want to remove this tag?',
    tariff: 'Tariff',
    tariff_test: 'Test for free',
    tariff_hit: 'Hit',
    tariff_free: 'Free forever',
    tariff_month: 'month',
    tariff_year: 'year',
    tariff_buy_caption: '{price} ₽ per {period}',
    tariff_buy_description: 'Site3D Configurator subscription payment per {period} according to the tariff {caption} from {userInfo}',
    tariff_economy: 'Save {price} ₽ per year',
    tariff_configurators: 'Up to {count} projects',
    tariff_configurators_no: 'Unlimited number of projects',
    tariff_time_active: 'Time limit: {time}\u00a0seconds',
    tariff_time_active_no: 'Unlimited working time',
    tariff_watermark_yes: 'Watermark',
    tariff_watermark_no: 'No watermark',
    tariff_ar_yes: 'Fitting in real space',
    tariff_ar_no: 'Without fitting in real space',
    tariff_limit_configurators: 'There are a maximum of {count} projects available on your tariff',
    tariff_no_edit_scene: 'Setting up a scene for all projects is only available on any paid tariff, starting with the tariff <a href="/buy">Basic</a>. If you need to test this feature, please write <a href="/support">to support</a>.',
    tariff_no_edit_configurators: 'Mass editing of projects is only available on the paid tariff <a href="/buy">Premium</a>. If you need to test this feature, please write <a href="/support">to support</a>.',
    tariff_limit_auto_created_configurators: 'The maximum number of automatically created projects has been exceeded. To resolve this issue, please write <a href="/support">to support</a>.',
    tariff_no_branding: 'Branding is only available on the paid tariff <a href="/buy">Premium</a>. If you need to test this feature, please write <a href="/support">to support</a>.',
    tariff_speed: 'Speed up browsing',
    tariff_1: 'Free',
    tariff_2: 'Start',
    tariff_3: 'Basic',
    tariff_4: 'Configurator',
    tariff_5: 'Premium',
    tariff_paid: 'Paid',
    tariff_additional_options: '<h3 class="contacts__blockCaption">Tariff customization options</h3>' +
      '<div class="contacts__blockContent contacts__blockContent_small">' +
      '<p>Increase the maximum size of models by 50&nbsp;Mb &mdash; 3&nbsp;000&nbsp;₽ per year</p>' +
      '<p>Additional domain &mdash; 6&nbsp;000&nbsp;₽ per year</p>' +
      '<p>The ability to automatically add an additional 1000 projects &mdash; 10&nbsp;000\u00a0₽ per year</p>' +
      '</div>',
    tariff_payment: '<h3 class="contacts__blockCaption">Details for the transfer in rubles to legal entities</h3>' +
      '<div class="contacts__blockContent contacts__blockContent_small">' +
      '<p>Individual entrepreneur Tkachenko Aleksey Sergeevich</p>' +
      '<p>Taxpayer Identification Number (INN): 272549116833</p>' +
      '<p>Bank: AO Tinkoff Bank</p>' +
      '<p>RCBIC: 044525974</p>' +
      '<p>Corr. acc.: 30101810145250000974</p>' +
      '<p>Acc.: 40802810100000511007</p>' +
      '</div>',
    tariff_start_offer: 'Free for 10 days at the tariff «Premium»',
    tariff_start_conditions: 'You need to subscribe to our <a href="https://t.me/site3d_configurator" target="_blank">telegram channel</a>',
    tariff_start_after: 'After the end of the trial period, all your projects are saved, and you will be transferred to a free tariff, where the following restrictions will apply: no more than 5\u00a0projects, up to 1\u00a0minute of viewing, the service logo.',
    technical_works: '<p>Everything will work soon!</p>' +
      '<p>We are improving the service for you. We apologize for the inconvenience.</p>' +
      '<p>You can also write to us <a href="/support">in support</a>.</p>',
    total: 'Total',
    type: 'Configurator type',
    type_1: 'Model',
    type_2: 'Planner',
    units_mm: 'mm',
    units_cm: 'cm',
    units_m: 'm',
    user_anonymous: 'Anonymous user',
    user_no_access: 'You do not have sufficient rights',
    user_date_reg: 'Registration date',
    user_date_update: 'Update date',
    user_date_active: 'Subscription expiration date',
    user_tariff: 'Tariff',
    user_additional_size: 'Additional size',
    user_additional_domains: 'Additional domains',
    user_additional_auto_created: 'Additional automatically created projects',
    user_is_paid: 'Paid account',
    user_name: 'Name',
    user_email: 'Email',
    user_phone: 'Phone',
    user_type: 'Account type',
    user_type_empty: 'Not defined',
    user_type_company: 'Business',
    user_type_freelancer: 'Personal',
    user_company: 'Company',
    user_position: 'Position',
    user_password: 'Password',
    user_purpose: 'Purpose of use',
    user_purpose_0: 'Not defined',
    user_purpose_1: 'Commercial',
    user_purpose_2: 'Educational',
    user_published_count: 'Number of published projects',
    user_is_partner: 'Partner',
    user_is_activated: 'Activated',
    user_activation_code: 'The activation code',
    user_activation_code_hint: 'If you have not received the activation code to your e-mail, write to us at <a href="/support">support</a>.',
    user_activation_code_description: 'We have sent an email with an activation code to your e-mail to confirm registration.',
    user_activation_check_error: 'Error entering the activation code',
    user_activation_resend_success: 'We have resent the activation code to your e-mail',
    user_ref_link: 'Referral link',
    user_status_test_end: 'The test period is ending',
    user_status_gone: 'Disappeared',
    user_status_not_active: 'Not active',
    user_status_without_prolongation: 'Without prolongation',
    user_status_active: 'Active',
    wrong_number_domains: '<p>The maximum number of domains has been exceeded: {domainsMaxCount}.</p>' +
      '<p>Subdomains are free in any number, starting with the Basic tariff.</p>' +
      '<p>For the purchase of additional domains, please write to <a href="/support">support</a></p>'
  }
}

export const isMessage = name => messages[LOCALES.RUSSIAN][name] !== undefined;
