import React from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import Html from '../html/Html';
import LinkText from '../html/LinkText';
import LinkButton from '../html/LinkButton';
import IconLink from '../html/IconLink';

const Footer = () =>
{
  const lang = useSelector(state => state.lang);
  
  const intl = useIntl();
  
  return (
    <div className="footer">
      <div className="footer__row">
        <div className="footer__item">
          <IconLink alias="logo" url="/" styles={['footer__logo']}/>
          <div className="text">
            <FormattedMessage id='phrase_copyright'/>
          </div>
          <div className="text">
            <LinkText url="https://reestr.digital.gov.ru/reestr/2486931/"><FormattedMessage
              id='footer_reestr'/></LinkText>
          </div>
        </div>
        <div className="footer__item">
          <div className="text">
            <LinkText url="/docs/user-agreement.pdf"><FormattedMessage id='docs_user_agreement'/></LinkText>
          </div>
          <div className="text">
            <LinkText url="/docs/public-offer.pdf"><FormattedMessage id='docs_public_offer'/></LinkText>
          </div>
          <div className="text">
            <Html value={<FormattedMessage id='docs_telegram'/>}/>
          </div>
        </div>
        <div className="footer__item">
          <div className="footer__contacts">
            <div className="footer__help">
              <div className="text"><FormattedMessage id='footer_help'/></div>
              <div className="footer__helpContent">
                <div className="footer__icons">
                  <div className="footer__icon">
                    <IconLink alias="telegramMessage" url={intl.formatMessage({id: 'help_telegram_url'})}
                              title={intl.formatMessage({id: 'help_telegram_title'})}/>
                  </div>
                  <div className="footer__icon">
                    <IconLink alias="email" url={intl.formatMessage({id: 'help_email_url'})}
                              title={intl.formatMessage({id: 'help_email_title'})}/>
                  </div>
                  <div className="footer__icon">
                    <div className="footer__iconText">
                      <LinkButton caption={<FormattedMessage id='footer_help_guide'/>}
                                  styleModifiers={['narrow', 'small', 'bright']}
                                  url={`/docs/guide_${lang}.pdf`}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer__channels">
              <div className="text"><FormattedMessage id='footer_channels'/></div>
              <div className="footer__channelsContent">
                <div className="footer__icons">
                  <div className="footer__icon">
                    <IconLink alias="telegramChannel" url={intl.formatMessage({id: 'channels_telegram_url'})}
                              title={intl.formatMessage({id: 'channels_telegram_title'})}/>
                  </div>
                  <div className="footer__icon">
                    <IconLink alias="vk" url={intl.formatMessage({id: 'channels_vk_url'})}
                              title={intl.formatMessage({id: 'channels_vk_title'})}/>
                  </div>
                  <div className="footer__icon">
                    <IconLink alias="zen" url={intl.formatMessage({id: 'channels_zen_url'})}
                              title={intl.formatMessage({id: 'channels_zen_title'})}/>
                  </div>
                  <div className="footer__icon">
                    <IconLink alias="rutube" url={intl.formatMessage({id: 'channels_rutube_url'})}
                              title={intl.formatMessage({id: 'channels_rutube_title'})}/>
                  </div>
                  <div className="footer__icon">
                    <IconLink alias="youtube" url={intl.formatMessage({id: 'channels_youtube_url'})}
                              title={intl.formatMessage({id: 'channels_youtube_title'})}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__row">
        <div className="footer__item">
          <Html value={<FormattedMessage id='block_seo_text'/>}/>
        </div>
        <div className="footer__item">
          <div className="assistants">
            <div className="assistants__content">
              <IconLink alias="skolkovo" url="https://navigator.sk.ru/orn/1126494" styles={['assistant']}/>
              <IconLink alias="ingria" url="https://ingria-startup.ru" styles={['assistant']}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
