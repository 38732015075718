import React from 'react';

const IconButton = ({alias, onClick, title, styles}) =>
{
  const stylesCur = ['iconButton', `iconButton_${alias}`];

  if (styles !== undefined)
    styles.forEach(item =>
    {
      stylesCur.push(item);
    });
  
  if (onClick === null)
    stylesCur.push('iconButton_notActive');

  return (
    <button className={stylesCur.join(' ')} onClick={onClick} title={title}/>
  );
};

export default IconButton;