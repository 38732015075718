import {configureStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import reducer from './reducer';
import {LOCALES} from '../i18n/locales';
import {sagaWatcher} from './sagas';
import {logMiddleware} from './middlewares';

const urlParams = new URLSearchParams(window.location.search);
const ref = parseInt(urlParams.get('ref') ?? 0);

let navigatorLocale = navigator.language;

if (navigatorLocale === 'ru')
{
  navigatorLocale = 'ru-RU';
}

if (Object.keys(LOCALES).find(key => LOCALES[key] === navigatorLocale) === undefined)
{
  navigatorLocale = undefined;
}

const locale = localStorage.getItem('locale') || navigatorLocale || LOCALES.RUSSIAN;
const lang = locale.split('-')[0];
const cookieAgree = localStorage.getItem('cookieAgree') || false;

const user = JSON.parse(localStorage.getItem('user')) || {
  status: 'logout',
  id: 0,
  email: '',
  days: 0,
  filled: 0
};

const preloadedState =
  {
    ref,
    locale,
    lang,
    languages: new Map([
      [LOCALES.RUSSIAN, {caption: 'Ru'}],
      [LOCALES.ENGLISH, {caption: 'En'}]
    ]),
    pages: new Map([
      ['main', {
        alias: 'main',
        url: '/',
        isMenu: true,
        isDescription: true,
        metaTitle: 'page_main_title',
        metaDescription: 'page_main_meta_description',
        showTitle: false
      }],
      ['cases', {
        alias: 'cases',
        url: '/cases',
        isMenu: true,
        isHeaderMenu: true,
        isDescription: true,
        metaTitle: 'page_cases_title',
        metaDescription: 'page_cases_meta_description'
      }],
      ['reviews', {
        alias: 'reviews',
        url: '/reviews',
        isMenu: true,
        isHeaderMenu: true,
        isDescription: true,
        metaTitle: 'page_reviews_title',
        metaDescription: 'page_reviews_meta_description'
      }],
      ['news', {
        alias: 'news',
        url: '/news',
        isMenu: true,
        isHeaderMenu: true,
        isDescription: true,
        metaTitle: 'page_news_title',
        metaDescription: 'page_news_meta_description'
      }],
      ['posts', {
        alias: 'posts',
        url: '/posts',
        isMenu: true,
        isDescription: true,
        metaTitle: 'page_posts_title',
        metaDescription: 'page_posts_meta_description'
      }],
      ['faq', {
        alias: 'faq',
        url: '/faq',
        isMenu: true,
        isHeaderMenu: true,
        isDescription: true,
        metaTitle: 'page_faq_title',
        metaDescription: 'page_faq_meta_description'
      }],
      ['account', {
        alias: 'account',
        url: '/account',
        isMenu: true,
        showTitle: false
      }],
      ['profile', {
        alias: 'profile',
        url: '/account/profile',
        isCenter: true
      }],
      ['buy', {
        alias: 'buy',
        url: '/buy',
        isMenu: true,
        isHeaderMenu: true,
        isCenter: true
      }],
      ['partners', {
        alias: 'partners',
        url: '/partners',
        isMenu: true,
        isDescription: true,
        isCenter: true
      }],
      ['api', {
        alias: 'api',
        url: '/api',
        isMenu: true,
        isDescription: true,
        isCenter: true
      }],
      ['support', {
        alias: 'support',
        url: '/support',
        isMenu: true,
        isHeaderMenu: true,
        isDescription: true,
        isCenter: true
      }],
      ['reg', {
        alias: 'reg',
        url: '/reg',
        isCenter: true
      }],
      ['admin', {
        alias: 'admin',
        url: '/admin'
      }],
      ['start', {
        alias: 'start',
        url: '/start'
      }],
      ['error', {
        alias: 'error',
        url: '/error'
      }],
      ['notFound', {
        alias: 'notFound',
        url: '/404'
      }]
    ]),
    user,
    loader: {
      isOpen: false,
      content: ''
    },
    alert: {
      isOpen: false,
      content: '',
      vars: []
    },
    buffer: {
      type: '',
      items: []
    },
    tariffs: [],
    userMaterials: {
      items: [],
      loading: false
    },
    globalMaterials: {
      items: [],
      loading: false
    },
    cookieAgree
  };

preloadedState.languages.forEach((item, itemLocale) =>
{
  item.locale = itemLocale;
  item.lang = itemLocale.split('-')[0];
  item.selected = itemLocale === locale;
});

const saga = createSagaMiddleware();

const store = configureStore({
  reducer,
  preloadedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false
    }).concat([saga, logMiddleware]),
});

saga.run(sagaWatcher);

export default store;
