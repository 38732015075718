import React from 'react';

const Button = ({
  caption,
  styles,
  styleModifiers,
  onClick
}) =>
{
  const stylesCur = ['button'];
  
  if (styles !== undefined)
  {
    styles.forEach(item => stylesCur.push(item));
  }
  
  if (styleModifiers !== undefined)
  {
    styleModifiers.forEach(item =>
    {
      stylesCur.push(`button_${item}`);
    });
  }
  
  return (
    <button className={stylesCur.join(' ')} onClick={onClick}>{caption}</button>
  );
};

export default Button;
