import React, {useCallback, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import IconButton from '../html/IconButton';
import LinkText from '../html/LinkText';
import {useOutsideClick} from '../../hooks/useOutsideClick';

const Menu = () =>
{
  const pages = useSelector(state => state.pages);
  
  const intl = useIntl();
  
  const location = useLocation();
  
  const [show, setShow] = useState(false);
  
  const menuWindowRef = useRef(null);
  const menuOutsideClick = useCallback(() => setShow(false), []);
  useOutsideClick(menuWindowRef, menuOutsideClick);
  
  return (
    <div className="menu">
      <IconButton alias="menu" onClick={() => setShow(!show)}
                  title={intl.formatMessage({id: 'phrase_menu'})}
                  styles={['menu__button']}/>
      {
        show &&
        <div ref={menuWindowRef} className="menuWindow">
          <IconButton alias="close" onClick={() => setShow(false)}
                      title={intl.formatMessage({id: 'phrase_close'})}
                      styles={['menuWindow__close']}/>
          <div className="menuWindow__buttons">
            {
              Array.from(pages.values()).filter(page => page.isMenu === true).map(page =>
              {
                const buttonStyles = ['menuWindow__button'];
                
                let url = page.url;
                
                if (((url === '/' || url === '/en') && (location.pathname === '/' || location.pathname === '/en')) || (!(url === '/' || url === '/en') && location.pathname.indexOf(url) !== -1))
                {
                  url = '';
                  buttonStyles.push('menuWindow__button_selected');
                }
                
                return (
                  <LinkText url={url}
                            onClick={() => setShow(false)}
                            styles={buttonStyles}
                            key={page.alias}><FormattedMessage id={`menu_${page.alias}_caption`}/></LinkText>
                )
              })
            }
          </div>
        </div>
      }
    </div>
  );
};

export default Menu;
