class Utility
{
  /**
   Метод возвращает строку с заглавной буквой
   @method capitalize
   @static
   @param     {string} value    Строка
   @return    {string}          Результат
   */
  static capitalize(value)
  {
    return value.split('_').map(item => item.charAt(0).toUpperCase() + item.slice(1)).join('');
  }
  
  /**
   Метод копирует в буфер обмена строку и возвращает истину в случае успеха
   @method copyToClipboard
   @static
   @param     {string} value    Строка
   @return    {boolean}         Результат
   */
  static copyToClipboard(value)
  {
    let result = true;
    const elem = document.createElement('textarea');
    elem.value = value;
    elem.setAttribute('readonly', '');
    elem.style.position = 'absolute';
    elem.style.left = '-9999px';
    document.body.appendChild(elem);
    elem.select();
    try
    {
      document.execCommand('copy');
    } catch (err)
    {
      result = false;
    }
    document.body.removeChild(elem);
    return result;
  }
  
  /**
   Метод возвращает истину, если значение является не пустым объектом
   @method isObject
   @static
   @param    {object} value    Значение
   */
  static isObject(value)
  {
    return value?.__proto__ && value.__proto__.constructor === Object;
  }
  
  /**
   Метод возвращает истину, если значение является не пустым массивом
   @method isArray
   @static
   @param    {object} value    Значение
   */
  static isArray(value)
  {
    return value !== null && Array.isArray(value);
  }
  
  /**
   Метод полностью клонируем объект
   @method cloneObject
   @static
   @param     {object} value    Объект
   @return    {object}          Результат
   */
  static cloneObject(value)
  {
    if (this.isObject(value))
    {
      return Object.keys(value).reduce((result, key) =>
      {
        return {
          ...result,
          [key]: this.cloneObject(value[key])
        };
      }, {});
    }
    
    if (Array.isArray(value))
    {
      return value.map(item => this.cloneObject(item));
    }
    
    if (value instanceof Blob)
    {
      const blob = new Blob([value], {type: value.type});
      blob.userData = {
        ...value.userData,
        url: URL.createObjectURL(blob)
      }
      return blob;
    }
    
    return value;
  }
  
  /**
   Метод копирует значения объекта в другой объект
   @method mergeObjects
   @static
   @param    {object} parentResult    Результирующий объект
   @param    {object} parentSource    Источник данных
   */
  static mergeObjects(parentResult, parentSource)
  {
    if (!parentSource)
    {
      return;
    }
    
    Object.keys(parentSource).forEach(key =>
    {
      const isParentResultObject = this.isObject(parentResult[key]);
      const isParenSourceObject = this.isObject(parentSource[key]);
      
      if (isParentResultObject && isParenSourceObject)
      {
        this.mergeObjects(parentResult[key], parentSource[key]);
        return;
      }
      
      if (isParentResultObject &&
        this.isArray(parentSource[key]) &&
        parentResult[key]?.items !== undefined)
      {
        parentResult[key].items = parentSource[key];
        return;
      }
      
      parentResult[key] = parentSource[key];
    });
  }
  
  /**
   Метод форматирования домена
   @method getDomain
   @static
   @param     {string} value    Домен
   @return    {string}          Результат
   */
  static getDomain(value)
  {
    return ['/', 'http:', 'https:'].reduce((result, item) => result.replaceAll(item, ''), value);
  }
  
  /**
   Метод форматирования числа
   @method formatNumber
   @static
   @param     {number} value    Число
   @return    {string}          Результат
   */
  static formatNumber(value)
  {
    return value.toString().replace(/(\d{1,3}(?=(\d{3})+(?:\.\d|\b)))/g, "$1\u00a0");
  }
  
  /**
   Метод форматирования цены
   @method getPrice
   @static
   @param     {number} value    Цена
   @return    {string}          Результат
   */
  static getPrice(value)
  {
    return this.formatNumber(value);
  }
  
  /**
   Метод форматирования размера файла
   @method getFileSize
   @static
   @param     {number} value      Размер в байтах
   @param     {number} decimal    Число знаков после запятой
   @return    {string}            Результат
   */
  static getFileSize(value, decimal = 1)
  {
    const thresh = 1024;
    
    if (Math.abs(value) < thresh)
    {
      return value + ' B';
    }
    
    const units = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let u = -1;
    const r = 10 ** decimal;
    
    do
    {
      value /= thresh;
      ++u;
    }
    while (Math.round(Math.abs(value) * r) / r >= thresh && u < units.length - 1);
    
    return value.toFixed(decimal) + ' ' + units[u];
  }
  
  /**
   Метод возвращает скорректированные данные для useRadio (например, из БД)
   @method getRadioValues
   @static
   @param     {object[]} values    Значения
   @return    {object[]}           Результат
   */
  static getRadioValues(values)
  {
    return values.map(item =>
    {
      const itemCur = {...item};
      itemCur.value = item.id;
      delete itemCur.id;
      return itemCur;
    });
  }
  
  /**
   Метод возвращает Blob версию файла
   @method fileToBlob
   @static
   @async
   @param     {string} path    Путь к файлу
   @return    {Blob}           Результат
   */
  static async fileToBlob(path)
  {
    if (!path)
    {
      return null;
    }
    
    const response = await fetch(path);
    
    if (!response.ok)
    {
      return null;
    }
    
    const blob = await response.blob();
    
    blob.userData = {
      format: path.slice(path.lastIndexOf('.') + 1).toLowerCase(),
      url: URL.createObjectURL(blob)
    };
    
    return blob;
  }
  
  /**
   Метод возвращает Blob версию файла из Base64 строки
   @method base64ToBlob
   @static
   @param     {string} data           Строка содержимого файла в Base64
   @param     {string} contentType    Тип файла
   @param     {number} sliceSize      Размер блока данных
   @return    {Blob}                  Результат
   */
  static base64ToBlob(data, contentType = '', sliceSize = 512)
  {
    const byteCharacters = window.atob(data);
    const byteArrays = [];
    
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize)
    {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++)
      {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    
    const blob = new Blob(byteArrays, {type: contentType});
    blob.userData = {
      format: contentType,
      url: URL.createObjectURL(blob)
    }
    
    return blob;
  }
  
  /**
   Метод вызывает загрузку Blob объекта в браузере
   @method downloadBlob
   @static
   @param     {string} fileName    Имя файла при загрузке
   @param     {Blob} blob          Blob объект
   */
  static downloadBlob(fileName, blob)
  {
    const link = document.createElement('a');
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
    URL.revokeObjectURL(link.href);
    link.remove();
  }
  
  /**
   Метод вызывает загрузку Html-файла в браузере
   @method downloadHtml
   @static
   @param     {string} fileName    Имя файла при загрузке
   @param     {string} content     Содержимое файла
   */
  static downloadHtml(fileName, content)
  {
    const html = `
      <html>
        <head>
          <style>
            h1, h2, h3, p
            {
              font-family: "Open Sans";
            }
            
            textarea
            {
              width: 100%;
              resize: none;
            }
          </style>
        </head>
        <body>
          ${content}
        </body>
      </html>`;
    
    const blob = new Blob([html], {type: 'html'});
    this.downloadBlob(fileName, blob);
  }
  
  /**
   Метод возвращает GUID
   @method getGuid
   @static
   */
  static getGuid()
  {
    const buf = [];
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charsLength = chars.length;
    
    for (let i = 0; i < 32; i++) {
      buf[i] = chars.charAt(Math.floor(Math.random() * charsLength));
    }
    
    return buf.join('');
  }
}

export default Utility
