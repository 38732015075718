import React, {useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {alertShow} from '../../store/actions';
import {useIntl} from 'react-intl';
import Html from './Html';

const Loader = () =>
{
  const lang = useSelector(state => state.lang);
  const loader = useSelector(state => state.loader);
  
  const dispatch = useDispatch();
  const intl = useIntl();
  
  const handleClick = useCallback(event => {
    /* eslint-disable */
    
    const hint = event.target.dataset.hint;
  
    if (hint)
    {
      dispatch(alertShow(intl.formatMessage({id: `hint_${hint}`})));
    }
  }, [lang])
  
  useEffect(() =>
  {
    /* eslint-disable */
    
    document.addEventListener('click', handleClick);
  
    return () =>
    {
      document.removeEventListener("click", handleClick);
    };
  }, [lang]);
  
  return (
    loader.isOpen &&
    <div className="loader">
      <div className="loader__content">
        <Html value={loader.content !== undefined ? loader.content : intl.formatMessage({id: 'phrase_loading'})}
              styles={['loader__text']}/>
        {
          loader.percent !== undefined &&
          <div className="loader__percent">
            {loader.percent} %
          </div>
        }
      </div>
    </div>
  );
};

export default Loader;