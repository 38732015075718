import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from '../html/Button';
import IconLink from '../html/IconLink';
import LinkText from '../html/LinkText';
import Menu from './Menu';
import Language from './Language';

const Header = () =>
{
  const pages = useSelector(state => state.pages);
  const user = useSelector(state => state.user);
  
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  
  const accountButtonCaption = user.id === 0 ? 'page_account_button_login' : 'page_account_button_enter';
  
  return (
    <div className="header">
      <div className="header__left">
        <Menu/>
        <div className="header__logoContainer">
          <IconLink alias="logo" url="/" styles={['header__logo']}/>
        </div>
      </div>
      <div className="header__center">
        <div className="header__menu">
          {
            Array.from(pages.values()).filter(page => page.isHeaderMenu === true).map(page =>
            {
              const buttonStyles = ['header__menuButton'];
              
              let url = page.url;
              
              if (((url === '/' || url === '/en') && (location.pathname === '/' || location.pathname === '/en')) || (!(url === '/' || url === '/en') && location.pathname.indexOf(url) !== -1))
              {
                url = '';
                buttonStyles.push('header__menuButton_selected');
              }
              
              return (
                <LinkText url={url}
                          styles={buttonStyles}
                          key={page.alias}><FormattedMessage id={`menu_${page.alias}_caption`}/></LinkText>
              )
            })
          }
        </div>
      </div>
      <div className="header__right">
        {
          user.status !== 'login' &&
          <div className="header__try">
            <Button caption={intl.formatMessage({id: 'header_try'})}
                    styleModifiers={['important']}
                    onClick={() => navigate(pages.get('reg').url)}
            />
          </div>
        }
        <div className="header__accountLink">
          {
            user.status === 'login' && user.adminRights !== '0' &&
            <IconLink alias="admin" url={pages.get('admin').url}
                      title={intl.formatMessage({id: 'page_admin_caption'})}/>
          }
          <Button caption={intl.formatMessage({id: accountButtonCaption})}
                  styleModifiers={['icon', 'account', 'grey']}
                  onClick={() => navigate(pages.get('account').url)}
          />
        </div>
        <Language/>
      </div>
    </div>
  )
    ;
};

export default Header;
