import {useRef, useLayoutEffect, useEffect} from 'react';
import Utility from '../libs/utility';

const useScript = options =>
{
  const readyToLoadRef = useRef(false);
  const optionsCurRef = useRef({
    autoLoad: true,
    parent: document.body,
    clearParent: false,
    params: {
      async: true
    }
  });
  const optionsCur = optionsCurRef.current;
  
  const load = () =>
  {
    if (!readyToLoadRef.current)
    {
      return;
    }
    
    Utility.mergeObjects(optionsCur, options);
    
    if (optionsCur.parent && typeof optionsCur.parent === 'string')
    {
      optionsCur.parent = document.getElementById(optionsCur.parent);
    }
    
    const params = optionsCur.params;
    
    if (!optionsCur.clearParent && params.id !== undefined && document.getElementById(params.id) !== null)
    {
      return;
    }
    
    const scriptElem = document.createElement('script');
    scriptElem.type = 'text/javascript';
    scriptElem.defer = true;
    
    if (options?.url !== undefined)
    {
      scriptElem.src = options.url;
    }
    
    if (options?.content !== undefined)
    {
      scriptElem.innerHTML = options.content;
    }
    
    Object.keys(params).forEach(key =>
    {
      if (typeof params[key] === 'object')
      {
        Object.keys(params[key]).forEach(keyParam => scriptElem[key][keyParam] = params[key][keyParam]);
      }
      else
      {
        scriptElem[key] = params[key];
      }
    });
    
    const data = optionsCur.data;
    
    if (data !== undefined)
    {
      Object.keys(data).forEach(key => scriptElem.dataset[key] = data[key]);
    }
    
    if (optionsCur.clearParent && optionsCur.parent?.innerHTML === scriptElem.outerHTML)
    {
      return;
    }
    
    if (optionsCur.parent)
    {
      const addScript = () =>
      {
        if (optionsCur.clearParent)
        {
          optionsCur.parent.innerHTML = '';
        }
        
        optionsCur.parent.appendChild(scriptElem);
        
        if (optionsCur.load)
        {
          optionsCur.load();
        }
      };
  
      addScript();
    }
  };
  
  useLayoutEffect(() =>
  {
    /* eslint-disable */
    
    /*Utility.mergeObjects(optionsCur, options);
    
    if (optionsCur.parent && typeof optionsCur.parent === 'string')
    {
      optionsCur.parent = document.getElementById(optionsCur.parent);
    }
    
    readyToLoadRef.current = true;*/
    
    /*if (optionsCur.autoLoad)
    {
      load();
    }*/
  }, []);
  
  useEffect(() =>
  {
    window.addEventListener('load', () =>
    {
      Utility.mergeObjects(optionsCur, options);
      
      if (optionsCur.parent && typeof optionsCur.parent === 'string')
      {
        optionsCur.parent = document.getElementById(optionsCur.parent);
      }
  
      readyToLoadRef.current = true;
      
      if (optionsCur.autoLoad)
      {
        load()
      }
    });
  }, []);
  
  return {
    load,
    getReadyToLoad: () => readyToLoadRef
  };
};

export default useScript;